import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function ChangesSkeleton() {
  return (
    <div className="change-container">
      <div className="change-wrapper">
        <div className="message">
          <div className="col min">
            <Skeleton variant="circle" width={40} height={40} />
          </div>
          <div className="col" style={{ marginLeft: "10px" }}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width="40%" />
          </div>
        </div>
      </div>
      <div className="change-wrapper">
        <div className="message">
          <div className="col min">
            <Skeleton variant="circle" width={40} height={40} />
          </div>
          <div className="col" style={{ marginLeft: "10px" }}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width="40%" />
          </div>
        </div>
      </div>
    </div>
  );
}
