import React, { useState } from "react";

export default function Checkbox(props) {
  const { data, id } = props;
  const [isChecked, setIsChecked] = useState(true);
  const handleChange = (e) => {
    props.updateShowColumnSettingsEvent(id, !isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <label htmlFor={`checkbox_${id}`} className="chart-history-wrapper">
      <span className="chart-history-checkbox">
        <span
          className={`chart-history-checkmark ${isChecked ? "checked" : ""} ${
            data.color
          }`}
        ></span>
      </span>
      <input
        type="checkbox"
        className="chart-history-hidden-checkbox"
        id={`checkbox_${id}`}
        onChange={handleChange}
      />
      {data.name}
    </label>
  );
}
