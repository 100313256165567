import React, { useContext, useEffect, useState } from "react";
import ProjectViewContext from "../../../../Context/ProjectViewContext";
import { Draggable } from "react-beautiful-dnd";
import NotificationComponent from "./NotificationComponent";
// ICONS
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import EventIcon from "@material-ui/icons/Event";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import UpdateIcon from "@material-ui/icons/Update";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import { BsPinFill, BsCheck2Circle } from "react-icons/bs";

export default function Card(props) {
  const { showTaskModalEvent } = useContext(ProjectViewContext);
  const { task, showDaysTo, showDateOfUpdate, showUsers, theme } = props;

  const [deduplicatedSessions, setDeduplicatedSessions] = useState([]); // локальная переменная для отображения уникальных активных пользователей в задаче

  useEffect(() => {
    if (task.activeSessions.length > 0) {
      let deduplicated = [];
      task.activeSessions.forEach((session) => {
        if (!deduplicated.some((item) => item.userId === session.userId)) {
          deduplicated.push(session);
        }
      });
      setDeduplicatedSessions(deduplicated);
    } else {
      setDeduplicatedSessions([]);
    }
  }, [task.activeSessions]);

  return (
    <Draggable draggableId={task.id.toString()} index={props.index}>
      {(provided) => (
        <div
          className={`task ${theme}`}
          onClick={() => showTaskModalEvent(task.id)}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {task.pinnedToMainscreen ? (
            <div className="task-bubble">
              <BsPinFill />
            </div>
          ) : null}

          {task.label.id ? (
            <div className={`label ${task.label.color} ${theme}`}>
              {task.label.name}
            </div>
          ) : null}
          <div className="task-body">
            <div className={`text medium break-word ${theme}`}>{task.name}</div>
            <div className="task-footer">
              <ul role="list" className="task-footer-container">
                {/* Уведомления об истечении срока */}
                {task.daysTo > 0 && showDaysTo == true ? (
                  <li className="footer-info-item">
                    <EventIcon style={{ fontSize: "1.2em" }} />
                    &#160; Через {task.daysTo} д.
                  </li>
                ) : null}
                {task.daysTo < 0 && showDaysTo == true ? (
                  <li className="footer-info-item" style={{ color: "#e25858" }}>
                    <EventIcon style={{ fontSize: "1.2em" }} />
                    &#160; Срок истёк: {task.daysTo * -1} д.
                  </li>
                ) : null}
                {task.daysTo == 0 && showDaysTo == true ? (
                  <li className="footer-info-item">
                    <EventIcon style={{ fontSize: "1.2em" }} />
                    &#160; Сегодня
                  </li>
                ) : null}

                {/* Дата последнего обновления статуса в задаче */}
                {showDateOfUpdate == true ? (
                  <li className="footer-info-item">
                    <UpdateIcon style={{ fontSize: "1.2em" }} />
                    &#160; {task.dateOfUpdate} д.
                  </li>
                ) : null}

                {/* Приложенные файлы */}
                {task.countFiles > 0 ? (
                  <li className="footer-info-item">
                    <AttachFileIcon style={{ fontSize: "1.2em" }} />
                    &#160;
                    {task.countFiles}
                  </li>
                ) : null}
                {/* Непрочиннаые сообщения */}
                {task.messsageNtfc > 0 ? (
                  <li className="footer-info-item active">
                    <MailOutlineIcon style={{ fontSize: "1.2em" }} />
                    &#160;
                    {task.messsageNtfc}
                  </li>
                ) : null}
                {/* Бюджет */}
                {task.budget > 0 ? (
                  <li className="footer-info-item">
                    <AccountBalanceWalletIcon style={{ fontSize: "1.2em" }} />
                    &#160;
                    {task.budget}
                  </li>
                ) : null}
                {/* Колокольчик */}
                {task.changeNtfc.count > 0 ? (
                  <NotificationComponent
                    notificationType={task.changeNtfc.action}
                    count={task.changeNtfc.count}
                  />
                ) : null}
              </ul>

              {showUsers == true ? (
                <div className="task-footer-container">
                  {task.subscribers.map((user, index) => (
                    <div
                      key={index}
                      className="avatar"
                      style={{
                        backgroundImage: `url(${user.userPic})`,
                      }}
                    >
                      {user.role == "doSomething" ? (
                        <div className="avatar-icon min green" />
                      ) : null}
                      {user.role == "waitSomething" ? (
                        <div className="avatar-icon min blue" />
                      ) : null}
                      {user.active == false ? (
                        <div className="avatar-blackout">
                          <BlurOnIcon />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ) : null}

              {task.columnSuccessStatus ? (
                <div style={{ marginTop: "20px" }}>
                  <span className="text green small icon-centered">
                    <BsCheck2Circle
                      className="filled-icon text-inline active"
                      style={{ marginRight: "3px" }}
                    />
                    Работы завершены
                  </span>
                </div>
              ) : null}

              {deduplicatedSessions.length > 0 ? (
                <>
                  <div className="task-footer-container">
                    <span
                      className="text small grey"
                      style={{ marginTop: "5px", marginRight: "5px" }}
                    >
                      Смотрит:
                    </span>
                    {deduplicatedSessions.map((item, key) => (
                      <div className="emoji-wrapper" key={key}>
                        <div
                          className="avatar small"
                          style={{ backgroundImage: `url(${item.userPic})` }}
                        ></div>
                        <span className="text small">{item.userName}</span>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            {/* {task.pinnedToMainscreen ? (
              // <div className={`task-status green`}></div>
            ) : null} */}
          </div>
        </div>
      )}
    </Draggable>
  );
}
