import React, { useState, useContext } from "react";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import Checkbox from "@material-ui/core/Checkbox";

export default function ListReadOnly(props) {
  const { theme } = props;
  const taskContext = useContext(ProjectViewContext);

  // Пример значение в list:
  // [{ id: 0, desc: "hello", check: false, editable: true }]
  const [list, setList] = useState(props.listData.data);

  return (
    <div className={`content-wrapper ${theme}`}>
      <div className="row">
        <div className="col min vertical-center">
          <h2 className="h2">Список задач</h2>
        </div>
      </div>
      <div className="task-list">
        {props.listData.data.map((item) => (
          <div className="row" key={item.id}>
            <div className="col min vertical-center">
              <Checkbox
                color="primary"
                disabled
                checked={item.checked}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <div className="col vertical-center">{item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
