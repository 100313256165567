import React from "react";
import Linkify from "react-linkify";
import { parseHtml } from "../../../UsefullFunctions";

//UI
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

export default function MessageItem(props) {
  const { item, theme } = props;

  const dateDecoration = (date) => {
    const [day, month, year, hour, minute] = date.split(" ").map(Number);
    const dateObj = new Date(year, month - 1, day, hour, minute);

    const dateStr = dateObj.toLocaleString("ru", {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    });
    return dateStr;
  };

  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          className="link-break-all"
          target="blank"
          href={decoratedHref}
          key={key}
        >
          {decoratedText}
        </a>
      )}
    >
      <div className={item.unread ? `message active` : `message`}>
        <div className="col min">
          <div
            className="message-img"
            style={{ backgroundImage: `url(${item.userPic})` }}
          >
            {item.delivered ? null : (
              <div className="message-loader-bckgd">
                <AccessAlarmIcon />
              </div>
            )}
          </div>
        </div>
        <div className="col">
          <div>
            <strong className="text medium">{item.userName}</strong>&#8194;
            <span className="text small dark-blue">
              {dateDecoration(item.dateFrom)}
            </span>
          </div>

          {item.answerId ? (
            <div className="answer-wrapper">
              <div className={`dark-yellow small`}>{item.answerUserName}</div>
              <div className={`text small grey`}>
                {parseHtml(item.answerMessage)}
              </div>
            </div>
          ) : null}

          <span className={`text medium break-word`}>
            {parseHtml(item.message)}
          </span>
          {item.emojies.length == 0 ? null : (
            <div className="row">
              {item.emojies.map((i, key) => (
                <div className="emoji-wrapper" key={key}>
                  <div className="emoji">{String.fromCodePoint(i.emoji)} </div>
                  {i.users ? (
                    <React.Fragment>
                      {i.users.map((i, key) => (
                        <div
                          className="avatar small"
                          style={{ backgroundImage: `url(${i.pic})` }}
                          key={key}
                        />
                      ))}
                    </React.Fragment>
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Linkify>
  );
}
