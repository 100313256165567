import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function DeskViewSkeleton(props) {
  const { theme } = props;
  return (
    <div>
      <div className={`column ${theme}`}>
        <div className="column-header">
          <Skeleton variant="text" height={35} width={200} />
        </div>
        <div className="column-body">
          <div className={`task ${theme}`}>
            <div className="task-body">
              <Skeleton variant="text" />
              <Skeleton variant="text" width={210} />
            </div>
            <div className="row">
              <div className="col min" style={{ marginRight: 10 }}>
                <Skeleton variant="circle" width={30} height={30} />
              </div>
              <div className="col min">
                <Skeleton variant="circle" width={30} height={30} />
              </div>
            </div>
          </div>
          <div className={`task ${theme}`}>
            <div className="task-body">
              <Skeleton variant="text" />
              <Skeleton variant="text" width={210} />
            </div>
            <div className="row">
              <div className="col min" style={{ marginRight: 10 }}>
                <Skeleton variant="circle" width={30} height={30} />
              </div>
              <div className="col min" style={{ marginRight: 10 }}>
                <Skeleton variant="circle" width={30} height={30} />
              </div>
              <div className="col min">
                <Skeleton variant="circle" width={30} height={30} />
              </div>
            </div>
          </div>
          <div className={`task ${theme}`}>
            <div className="task-body">
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" width={210} />
            </div>
            <div className="row">
              <div className="col min" style={{ marginRight: 10 }}>
                <Skeleton variant="circle" width={30} height={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
