import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

// ICONS
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

export default function BudgetLight(props) {
  const { changeWidgetSettingsEvent, saveBudget, theme } = props;

  const [showModal, setShowModal] = useState(false);
  const [budget, setBudget] = useState(0);

  const [showWidgetNav, setShowWidgetNav] = useState(false);

  function saveModalForm() {
    saveBudget(budget);
    setShowModal(false);
  }

  return (
    <div className={`content-wrapper ${theme}`}>
      <div className="row">
        {showWidgetNav ? (
          <div className="col min vertical-center">
            <RemoveCircleOutlineIcon
              className="icon small red"
              style={{ marginRight: "20px", cursor: "pointer" }}
              onClick={() => {
                changeWidgetSettingsEvent("showBudget", false);
              }}
            />
          </div>
        ) : null}
        <div className="col min vertical-center">
          <h2
            className="h2 active"
            onClick={() => {
              setShowWidgetNav(!showWidgetNav);
            }}
          >
            Бюджет
          </h2>
        </div>
        <div className="col min vertical-center">
          <button className={`button small`} onClick={() => setShowModal(true)}>
            Изменить
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col min">
          <ul role="list">
            <li>
              Общая сумма:&nbsp;
              <strong>{props.budget}</strong>
            </li>
          </ul>
        </div>
      </div>

      {/* МОДАЛЬНОЕ ОКНО */}
      {showModal ? (
        <React.Fragment>
          <div
            className="modal-cover active"
            onClick={() => setShowModal(false)}
          ></div>
          <div className="modal-wrapper">
            <div className="simple-modal">
              <div className="modal-header">
                <h1 className="h1">Стоимость проекта</h1>
              </div>

              <div className="modal-content">
                <TextField
                  type="number"
                  label="Стоимость"
                  inputProps={{ autoFocus: true }}
                  defaultValue={props.budget}
                  onChange={(event) => setBudget(parseInt(event.target.value))}
                />
              </div>

              <div className="modal-footer">
                <button className=" button" onClick={() => setShowModal(false)}>
                  Закрыть
                </button>
                <button
                  className=" button primary"
                  onClick={() => saveModalForm()}
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}
