import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { HOST } from "../AppConfig";

const rest = axios.create({
  withCredentials: true,
  baseURL: `${HOST}/api`,
});

const refreshAuthLogic = (failedRequest) =>
  axios
    .get(`${HOST}/api/refresh_token`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("refresh_token"),
        // "Content-Type": "multipart/form-data"
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      localStorage.setItem("access_token", response.data.access_token);
      return Promise.resolve();
    })
    .catch((error) => {
      // console.log('Словил ошибку')
      // console.log(error.response.data)
      document.location.href = `/logout`;
    });

rest.interceptors.request.use((config) => {
  let token = localStorage.getItem("access_token");
  if (token == null) {
    document.location.href = `/logout`;
  }

  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "access_token"
  )}`;
  return config;
});

// ЭТО КАЙФ, А НЕ ФУНКЦИЯ! Заменяет код ниже
createAuthRefreshInterceptor(rest, refreshAuthLogic);

export default rest;
