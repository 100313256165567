import React from "react";
import parse, { domToReact } from "html-react-parser";

// Функция для очистки строки от HTML тегов
export const parseHtml = (html) => {
  const result = parse(html);
  return result;
};

// Функция для очистки строки от HTML тегов, меняем <div> <p> <li> на \n и удаляем все остальные теги
// делаю это без parse
export const stripHtml = (html) => {
  const result = html
    .replace(/<(div|p|li)>/gi, "\n")
    .replace("&nbsp;", " ")
    .replace(/<[^>]*>/g, "")
    .trim();
  return result;
};

// Функция для отправки событий в Google Analytics
export const sendEvent = (action, category, label, value) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
