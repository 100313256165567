// import rest from "../http/axios";
import axios from "axios";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { HOST } from "../AppConfig";

//UI
import Slide from "@material-ui/core/Slide";

export default function SignUp(props) {
  const { registerButtonRef, showSnackbar } = props;
  const [showModal, setShowModal] = useState(false);

  const submitButtonRef = useRef();

  function openModal() {
    setShowModal(true);
  }
  function closeModal() {
    setShowModal(false);
  }

  function submitForm(event) {
    event.preventDefault();
    let email = event.target[0].value;
    let pass1 = event.target[1].value;
    let pass2 = event.target[2].value;

    if (pass1 === pass2) {
      axios
        .post(`${HOST}/api/sign_up`, { email: email, password: pass1 })
        .then((response) => {
          let rsp = response.data;
          props.authorizeUserEvent(rsp);
          closeModal();
          showSnackbar(rsp.message);
        })
        .catch((error) => {
          showSnackbar(error.request.response, "error");
          console.log(error.response.data);
        });
    } else {
      showSnackbar("Указанные пароли отличаются", "error");
    }
  }
  return (
    <React.Fragment>
      <div className="button-wrapper center">
        <button
          onClick={openModal}
          className=" button warning"
          ref={registerButtonRef}
        >
          БЕСПЛАТНАЯ РЕГИСТРАЦИЯ
        </button>
      </div>

      {showModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Быстрая регистрация</h1>
                </div>
                <div className="modal-content">
                  <form className="login-form w-form" onSubmit={submitForm}>
                    <input
                      type="text"
                      className="input login-element w-input"
                      maxLength={80}
                      placeholder="Email"
                    />
                    <input
                      type="password"
                      className="input w-input login-element"
                      maxLength={80}
                      placeholder="Пароль"
                      required
                    />
                    <input
                      type="password"
                      className="input w-input login-element"
                      maxLength={80}
                      placeholder="Повторите пароль"
                      required
                    />
                    <div className="login-form-footer">
                      <input
                        type="submit"
                        ref={submitButtonRef}
                        defaultValue="Войти"
                        style={{ display: "none" }}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Закрыть
                  </button>
                  <button
                    className=" button primary"
                    onClick={() => submitButtonRef.current.click()}
                  >
                    Зарегистрироваться
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
