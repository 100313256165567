import React, { useState, useEffect } from "react";

export default function ProjectNameReadOnly(props) {
  return (
    <div className="content-wrapper">
      <React.Fragment>
        <span className="text grey">Название проекта</span>
        <div className="row">
          <div className="col min vertical-center">
            <h1 className="h1">{props.projectName}</h1>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}
