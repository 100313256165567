import React from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

export default function LabelReadOnly(props) {
  const { theme, activeLabel } = props;
  return (
    <div className="modal-topline-wrapper">
      <div className={`label active ${theme} ${activeLabel.color}`}>
        <LocalOfferIcon style={{ fontSize: "1.2em" }} />
        <span style={{ marginLeft: "5px" }}>{activeLabel.name}</span>
      </div>
      <span className="text grey bold small">Только комментарии</span>
    </div>
  );
}
