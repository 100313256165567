import React, { useState, useContext } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import ProjectViewContext from "../../../../Context/ProjectViewContext";

export default function TemplateItem(props) {
  const { item, removeTemlateItemFromList } = props;

  const { showTaskModalEvent } = useContext(ProjectViewContext);
  const [showMenu, setShowMenu] = useState(false);

  const removeTemplateItem = () => {
    removeTemlateItemFromList(item.id);
  };

  return (
    <label className={`select-item ${item.active}`} key={item.id}>
      <input
        type="radio"
        id={item.id}
        name="selectTemplate"
        className="hidden-radio"
        value={item.id}
      />
      <span className="w-form-label">{item.name}</span>
      {item.id == 0 ? null : (
        <React.Fragment>
          <div className="col"></div>

          <div className="col min" style={{ position: "relative" }}>
            <div
              className="active-icon"
              onClick={() => {
                setShowMenu(true);
              }}
            >
              <BsThreeDotsVertical />
            </div>
            {showMenu ? (
              <div className="dropdown-menu">
                <div
                  className="dropdown-element"
                  onClick={() => {
                    showTaskModalEvent(item.parentId);
                    setShowMenu(false);
                  }}
                >
                  <span className="text"> Открыть шаблон</span>
                </div>
                <div
                  className="dropdown-element"
                  onClick={() => {
                    removeTemplateItem();
                    setShowMenu(false);
                  }}
                >
                  Удалить
                </div>
              </div>
            ) : null}
          </div>
          {showMenu ? (
            <div
              className="invisible-cover"
              onClick={() => setShowMenu(false)}
            ></div>
          ) : null}
        </React.Fragment>
      )}
    </label>
  );
}
