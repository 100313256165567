import React, { useState, useEffect, useRef } from "react";

function InputField(props) {
  const { defaultValue, iconObj, label, autoFocus, theme } = props;
  const [value, setValue] = useState(defaultValue);
  const [minimizeLabel, setMinimizeLabel] = useState(defaultValue !== "");

  const labelBottom = minimizeLabel ? "30px" : "7px";
  const labelFontSize = minimizeLabel ? "0.9em" : "1.1em";
  const labelColor = minimizeLabel ? "#3b5697" : "grey";

  const inputRef = useRef(null);

  const onChangeHandler = (event) => {
    setValue(event.target.value);
    props.onChangeEvent(event.target.value);
  };

  const onBlurHandler = () => {
    if (value === "") {
      setMinimizeLabel(false);
    }
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="input-wrapper">
      <input
        type="text"
        ref={inputRef}
        value={value}
        className={`input w-input ${theme}`}
        onChange={onChangeHandler}
        onFocus={() => setMinimizeLabel(true)}
        onBlur={onBlurHandler}
      />
      <div className="input-label" style={{ bottom: labelBottom }}>
        <span
          className="text grey"
          style={{ fontSize: labelFontSize, color: labelColor }}
          onClick={() => inputRef.current.focus()}
        >
          {label}
        </span>
      </div>
      <div className="input-icon-wrapper">{iconObj}</div>
    </div>
  );
}

InputField.defaultProps = {
  defaultValue: "",
};

export default InputField;
