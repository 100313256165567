import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function ProjectListSkeleton(props) {
  const { theme } = props;
  return (
    <div className="projects-container">
      <div className={`project-card-wrap`}>
        <div className="project-card-head">
          <div className="project-card-info">
            <Skeleton variant="text" height={35} width={200} />
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={150} />
          </div>

          <Skeleton
            className="project-card-image"
            variant="rect"
            width={100}
            height={70}
          />
        </div>
      </div>
      <div className={`project-card-wrap`}>
        <div className="project-card-head">
          <div className="project-card-info">
            <Skeleton variant="text" height={35} width={200} />
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={150} />
          </div>

          <Skeleton
            className="project-card-image"
            variant="rect"
            width={100}
            height={70}
          />
        </div>
      </div>
      <div className={`project-card-wrap`}>
        <div className="project-card-head">
          <div className="project-card-info">
            <Skeleton variant="text" height={35} width={200} />
            <Skeleton variant="text" width={150} />
            <Skeleton variant="text" width={150} />
          </div>

          <Skeleton
            className="project-card-image"
            variant="rect"
            width={100}
            height={70}
          />
        </div>
      </div>
    </div>
  );
}
