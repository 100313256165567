import React, { useState, useContext, useRef } from "react";
import rest from "../../../../http/axios";
import ProjectViewContext from "../../../../Context/ProjectViewContext";

//ICONS
import { BsBellFill } from "react-icons/bs";
import CloseIcon from "@material-ui/icons/Close";

// UI
import Slide from "@material-ui/core/Slide";
import ChangesSkeleton from "./ChangesSkeleton";
// ELEMENTS
import Switch from "@material-ui/core/Switch";

export default function Changes(props) {
  const { projectId } = props;

  const [showSkeleton, setShowSkeleton] = useState(true);
  const [showPanel, setShowPanel] = useState(false);
  const [nextPage, setNextpage] = useState(1);
  const [changes, setChanges] = useState([]);
  const [filterMyTasks, setFilterMyTasks] = useState(false);

  const changeContainerRef = useRef();
  const taskContext = useContext(ProjectViewContext);

  function openPanel() {
    setShowPanel(true);
    setShowSkeleton(false);

    // при первом открытии панели загружаем первую страницу с использование фильтра "показывать все задачи"
    if (nextPage === 1) {
      loadNextPage(1, filterMyTasks);
    }
  }

  function closePanel() {
    setShowPanel(false);
    setShowSkeleton(true);
  }

  function loadNextPage(page = 1, filterMyTasks = false) {
    rest
      .get(`/load_changes/${projectId}/${page}/${filterMyTasks}`)
      .then((response) => {
        let rsp = response.data;
        let allChanges = [];
        if (page === 1) {
          allChanges = rsp.changes;
        } else {
          allChanges = changes.concat(rsp.changes);
        }
        setNextpage(page + 1);
        setChanges(allChanges);
      });
  }

  function openTask(taskId) {
    closePanel();
    let newChanges = changes.map((item) => {
      if (item.taskId == taskId) {
        item.notifications = 0;
      }
      return item;
    });
    setChanges(newChanges);
    taskContext.showTaskModalEvent(taskId);
  }

  function handleScroll(event) {
    const {
      scrollTop,
      clientHeight,
      scrollHeight,
    } = changeContainerRef.current;

    // console.log(scrollTop + clientHeight, scrollHeight);
    if (scrollTop + clientHeight + 5 > scrollHeight) {
      loadNextPage(nextPage, filterMyTasks);
    }
  }

  const toggleFilterMyTasks = (event) => {
    let newFilterStatus = event.target.checked;
    // обновляю значение фильтра для его дальнешйшего использования при скролле
    setFilterMyTasks(newFilterStatus);
    setNextpage(1);
    loadNextPage(1, newFilterStatus);
  };

  return (
    <React.Fragment>
      <div className="top-menu-nav desktop-only min" onClick={openPanel}>
        <BsBellFill className="icon big black" style={{ cursor: "pointer" }} />
      </div>

      {showPanel ? (
        <div className="task-cover-zone active" onClick={closePanel}></div>
      ) : null}

      <Slide direction="left" in={showPanel} mountOnEnter unmountOnExit>
        <div className="right-panel active">
          <div className="row modal-header">
            <div className="col">
              <h1 className="h1">Изменения</h1>
            </div>
            <div className="col min">
              <div
                className="active-icon"
                style={{ marginRight: "0px" }}
                onClick={closePanel}
              >
                <CloseIcon className="acitve-icon" />
              </div>
            </div>
          </div>

          {showSkeleton ? (
            <ChangesSkeleton />
          ) : (
            <div
              className="change-container"
              ref={changeContainerRef}
              onScroll={(event) => handleScroll()}
            >
              {changes.map((item) => (
                <div
                  className="change-wrapper"
                  key={item.id}
                  onClick={() => openTask(item.taskId)}
                >
                  <div className="message">
                    <div className="col min">
                      <div
                        className="message-img"
                        style={{ backgroundImage: `url(${item.userPic})` }}
                      />
                    </div>
                    <div className="col">
                      <div className="text author-name dark-grey">
                        <span>{item.userName}</span>{" "}
                        <span>{item.dateFrom}</span>
                      </div>
                      <div className="text bold">{item.taskName}</div>
                      <p className="text dark-grey">{item.change}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="row">
            <div className="col vertical-center">
              <span className="text small grey">
                Показывать изменения только моих задач
              </span>
            </div>
            <div className="col min">
              <Switch
                checked={filterMyTasks}
                onChange={(event) => toggleFilterMyTasks(event)}
                name="filterMyTasksSwitcher"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          </div>
        </div>
      </Slide>
    </React.Fragment>
  );
}
