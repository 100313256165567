import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import rest from "../http/axios";

export default function SubscribeProjectWithLink() {
  const { accessType, key } = useParams();
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!accessType || !key) return;
    rest
      .post(`/access_project_with_link`, {
        accessType: accessType,
        key: key,
      })
      .then((response) => {
        setLoaded(true);
        const resp = response.data;
        navigate(`/web_app/project/${resp.projectId}`);
      })
      .catch((error) => {
        setLoaded(true);
      });
  }, []);

  return (
    <React.Fragment>
      {loaded ? (
        <React.Fragment>
          <div className="row">
            <p className="text">Ссылка на проект более не доступна</p>
          </div>
          <div className="row">
            <Link to="/web_app">Вернуться к списку проектов</Link>
          </div>
        </React.Fragment>
      ) : (
        <div className="row">
          <p className="text">Проверка доступности проекта</p>
        </div>
      )}
    </React.Fragment>
  );
}
