import React from "react";
import { BsCheck2Circle } from "react-icons/bs";

export default function ColumnStatusView(props) {
  const { columnSuccessStatus, columnName, changeTaskColumnSuccessStatus } =
    props;

  return (
    <div className="row">
      <div className="col">
        <span className="text grey small">
          Подтверить завершение работ в колонке <b>{columnName}</b>? В статусе
          "Работы завершены" время нахождения задачи в колонке не учитывается
        </span>
        <div className="button-wrapper center">
          {columnSuccessStatus ? (
            <button
              className="button done outline"
              onClick={() => {
                changeTaskColumnSuccessStatus(false);
              }}
            >
              <BsCheck2Circle
                className="filled-icon active"
                style={{ marginRight: "5px" }}
              />
              Работы завершены
            </button>
          ) : (
            <button
              className="button grey outline"
              onClick={() => {
                changeTaskColumnSuccessStatus(true);
              }}
            >
              <BsCheck2Circle
                className="filled-icon"
                style={{ marginRight: "5px" }}
              />
              Подтвердить
            </button>
          )}
        </div>
      </div>

      {/* {status ? (
        <div
          className="horizontal-col min"
          onClick={() => {
            setStatus(!status);
          }}
        >
          <BsCheck2Circle className="filled-icon active" />
        </div>
      ) : (
        <div
          className="horizontal-col min"
          onClick={() => {
            setStatus(!status);
          }}
        >
          <BsCheck2Circle className="filled-icon" />
        </div>
      )} */}
    </div>
  );
}
