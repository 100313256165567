import React, { useState, useRef, useEffect } from "react";

export default function ProjectImageReadOnly(props) {
  return (
    <div className="content-wrapper">
      <span className="text grey">Картинка проекта:</span>
      <div
        className="master-image"
        style={{ backgroundImage: `url(${props.projectPic})` }}
      ></div>
    </div>
  );
}
