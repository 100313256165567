import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

// ICONS
import DeleteIcon from "@material-ui/icons/Delete";

export default function ListDeliminator(props) {
  const { item, theme, deleteElement, updateDesc } = props;

  const [editable, setEditable] = useState(false);
  const [inputVal, setInputVal] = useState(item.desc);

  function triggerStaticMode() {
    setTimeout(function() {
      setEditable(false);
      updateDesc(item.id, inputVal);
    }, 200);
  }

  return (
    <React.Fragment>
      {editable ? (
        <div className="input-wrapper">
          <TextField
            className={`w-input input ${theme}`}
            style={{ paddingRight: "30px" }}
            inputProps={{ autoFocus: true }}
            onBlur={() => triggerStaticMode()}
            value={inputVal}
            multiline
            maxRows={2}
            onChange={(event) => {
              setInputVal(event.target.value);
            }}
          />
          <DeleteIcon
            className="input-icon-wrapper active"
            onClick={() => deleteElement(item.id)}
            style={{ fontSize: "1.5em" }}
          />
        </div>
      ) : (
        <span className="text bold" onClick={() => setEditable(true)}>
          {item.desc}
        </span>
      )}
    </React.Fragment>
  );
}
