import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
// import AppContext from "../Context/AppContext";

// ICONS
import SearchIcon from "@material-ui/icons/Search";

export default function TopMenu(props) {
  const { filterProjects, theme } = props;

  const [showSearch, setShowSearch] = useState(false);
  const searchInputRef = useRef();

  return (
    <div className={`top-menu ${theme}`} style={{ position: "relative" }}>
      {/* Заглушка для растягивания экрана */}
      <Link to="/" className="top-menu-nav mobile-only">
        <div className={`mobile-logo ${theme}`}></div>
      </Link>
      <div className={`top-menu-search-wrapper ${showSearch ? "active" : ""}`}>
        <div className="search-form w-form">
          <div className="input-wrapper" style={{ marginTop: "5px" }}>
            <input
              type="text"
              className={`w-input input search`}
              placeholder="Найти проект"
              onChange={(event) => filterProjects(event)}
              onBlur={() => setShowSearch(false)}
              ref={searchInputRef}
            />
            <div className="input-icon-wrapper">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="top-menu-nav mobile-only"></div>
      <div
        className="top-menu-nav min mobile-only"
        onClick={() => {
          setShowSearch(!showSearch);
          searchInputRef.current.focus();
        }}
      >
        <SearchIcon className={`icon ${theme}`} />
      </div>

      {/* Messages */}
      {/* <Link to="/messages" className="top-menu-nav min show-both">
        <Badge badgeContent={`5`} color="secondary" invisible={true}>
          <MailIcon className="icon" />
        </Badge>
      </Link> */}
      {/* End Messages */}
    </div>
  );
}
