import React, { useState } from "react";

//ELEMENTS
import Checkbox from "@material-ui/core/Checkbox";

export default function LabelFilter(props) {
  const { label, checked, modifyFilterSet } = props;
  const [checkboxStatus, setCheckboxStatus] = useState(checked);

  const toggleCheckbox = (event) => {
    let status = event.target.checked;
    setCheckboxStatus(status);
    if (status == true) {
      modifyFilterSet("add", "labels", label.id, label.name);
    } else {
      modifyFilterSet("del", "labels", label.id);
    }
  };
  return (
    <li className="select-item">
      <div className="col">
        <span className="text">{label.name}</span>
      </div>
      <div className="col min">
        <Checkbox
          checked={checkboxStatus}
          onChange={toggleCheckbox}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
    </li>
  );
}
