// DatePickerComponent.js
import React, { useState, useEffect, useRef } from "react";
import { format, addDays, subDays, isSameDay } from "date-fns";
import { ru } from "date-fns/locale";

const DatePicker = ({ selected, onChange, dateFormat, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [days, setDays] = useState([]);
  const [calendarPosition, setCalendarPosition] = useState({});

  const inputRef = useRef(null);
  const calendarRef = useRef(null);

  useEffect(() => {
    // Обновление массива дней при смене месяца
    generateDays(currentMonth);
  }, [currentMonth]);

  const generateDays = (month) => {
    const startOfMonth = new Date(month.getFullYear(), month.getMonth(), 1);
    const endOfMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const daysArray = [];

    // Добавление дней месяца
    for (let i = 1; i <= endOfMonth.getDate(); i++) {
      daysArray.push(new Date(month.getFullYear(), month.getMonth(), i));
    }

    // Заполнение недостающих дней в начале
    const startDay = startOfMonth.getDay() || 7; // Воскресенье = 0, сделаем его = 7
    for (let i = 1; i < startDay; i++) {
      daysArray.unshift(subDays(startOfMonth, startDay - i));
    }

    // Заполнение недостающих дней в конце
    const endDay = endOfMonth.getDay() || 7;
    for (let i = endDay + 1; i <= 7; i++) {
      daysArray.push(addDays(endOfMonth, i - endDay));
    }

    setDays(daysArray);
  };

  const handleDayClick = (day) => {
    onChange(day);
    setIsOpen(false);
  };

  const handleToggleCalendar = (event) => {
    setIsOpen((prev) => !prev);
    let x = event.clientX + 10;
    let y = event.clientY + 10;
    console.log(x, y);
    console.log(window.innerWidth, window.innerHeight);
    if (window.innerHeight - y < 300) y = window.innerHeight - 300;
    if (window.innerWidth - x < 300) x = window.innerWidth - 300;
    setCalendarPosition({ x, y });
  };

  const handleMonthChange = (increment) => {
    setCurrentMonth((prev) => addDays(prev, 30 * increment)); // +30 или -30 дней
  };

  const formattedDate = selected
    ? format(selected, dateFormat, { locale: ru })
    : "";

  return (
    <div className="date-picker">
      <input
        type="text"
        ref={inputRef}
        value={formattedDate}
        onClick={(event) => handleToggleCalendar(event)}
        className={className}
        readOnly
        placeholder="Выберите дату"
      />
      {isOpen && (
        <div
          className="calendar"
          ref={calendarRef}
          style={{
            transform: `translate(${calendarPosition.x}px, ${calendarPosition.y}px)`,
          }}
        >
          <div className="calendar-header">
            <button
              type="button"
              className="button"
              onClick={() => handleMonthChange(-1)}
            >
              «
            </button>
            <span>{format(currentMonth, "MMMM yyyy", { locale: ru })}</span>
            <button
              type="button"
              className="button"
              onClick={() => handleMonthChange(1)}
            >
              »
            </button>
          </div>
          <div className="calendar-grid">
            {days.map((day) => (
              <div
                key={day}
                className={`calendar-day ${
                  isSameDay(day, selected) ? "selected" : ""
                }`}
                onClick={() => handleDayClick(day)}
              >
                {format(day, "d", { locale: ru })}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
