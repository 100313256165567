import React from "react";

export default function ActiveSessionsView(props) {
  const { activeSessions } = props;

  return (
    <div className="content-wrapper">
      {activeSessions.length > 0 ? (
        <div style={{ marginLeft: "10px" }}>
          <h2>Сейчас смотрят</h2>

          <div className="task-footer-container">
            {activeSessions.map((item, key) => (
              <div className="emoji-wrapper" key={key}>
                <div
                  className="avatar small"
                  style={{ backgroundImage: `url(${item.userPic})` }}
                ></div>
                <span className="text small grey">{item.userName}</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
