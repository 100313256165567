import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import BasicContext from "./Context/BasicContext";
// ICONS
import ChatIcon from "@material-ui/icons/Chat";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import ForumIcon from "@material-ui/icons/Forum";
import PersonIcon from "@material-ui/icons/Person";
import TelegramIcon from "@material-ui/icons/Telegram";

export default function LeftMenu() {
  const context = useContext(BasicContext);
  const theme = localStorage.getItem("theme");

  return (
    <div className={`left-menu ${theme}`}>
      <Link to={`/web_app`}>
        <div className="menu-logo-container">
          <div className="menu-logo"></div>
        </div>
      </Link>

      <div className="menu-items-container">
        <ul role="list" className="menu-list">
          <li className="no-margin">
            <Link to={`/web_app`} className="menu-item">
              <HomeIcon />
              &#8194; Мои проекты
            </Link>
          </li>
          {/* <li>
            <Link to={`/web_app/chat`} className="menu-item">
              <ForumIcon />
              &#8194; Обсуждения
            </Link>
          </li> */}
          <li className="no-margin">
            <Link to={`/web_app/messages`} className="menu-item">
              <ChatIcon />
              &#8194; Сообщения
              {context.messageNtfc > 0 ? (
                <span className="mark">{context.messageNtfc}</span>
              ) : null}
            </Link>
          </li>
          {/* <li className="menu-item">
            <InfoIcon />
            &#8194;
            <Link to={`/web_app/changes`} className="menu-text">
              Изменения
            </Link>
            {context.changeNtfc > 0 ? (
              <span className="mark">{context.changeNtfc}</span>
            ) : null}
          </li> */}
        </ul>
      </div>
      <div className="menu-footer-container">
        <ul className="menu-list">
          <li className="no-margin">
            <Link to={`/web_app/profile`} className="menu-item">
              <PersonIcon />
              &#8194; Профиль
            </Link>
          </li>
          <li className="no-margin">
            <a
              href={`https://t.me/branzy_club`}
              target="blank"
              className="menu-item"
            >
              <TelegramIcon />
              &#8194; Канал в Telegram
            </a>
          </li>
          <li className="no-margin">
            <Link to={`/logout`} className="menu-item">
              <ExitToAppIcon />
              &#8194; Выйти
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
