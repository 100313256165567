import React, { useState, useEffect, useContext } from "react";

export default function ActiveUsers(props) {
  const { activeSessions } = props;

  const [visibleUsers, setVisibleUsers] = useState([]);
  const [additionalNumberOfUsers, setAdditionalNumberOfUsers] = useState(0);

  useEffect(() => {
    // В activeSessions могут быть дубликаты, т.к у пользователя может быть открыто несколько вкладок
    // Поэтому фильтруем для вывода на экран уникальные значения
    setVisibleUsers(
      activeSessions.filter(
        (obj, index) =>
          activeSessions.findIndex(
            (innerObj) => innerObj.userId === obj.userId
          ) === index
      )
    );
  }, [activeSessions]);

  return (
    <div
      className="top-menu-nav min desktop-only"
      style={{ marginRight: "10px", cursor: "pointer" }}
    >
      {visibleUsers.map((user, key) => (
        <div
          className="avatar grouped"
          key={key}
          style={{
            backgroundImage: `url(${user.userPic})`,
            marginTop: "0px",
          }}
        ></div>
      ))}
      {additionalNumberOfUsers > 0 ? (
        <span style={{ marginLeft: "15px" }}>+{additionalNumberOfUsers}</span>
      ) : null}
    </div>
  );
}
