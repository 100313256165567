import React, { useState, useEffect } from "react";

export default function ProjectName(props) {
  const [projectName, setProjectName] = useState("");
  const [editMode, setEditMode] = useState(false);

  function openEditMode() {
    setProjectName(props.projectName);
    setEditMode(true);
  }

  function closeEditMode() {
    props.adoptProjectNameEvent(projectName);
    setEditMode(false);
  }

  return (
    <div className="content-wrapper">
      {editMode ? (
        <React.Fragment>
          <h1 className="h1">Название проекта</h1>
          <div className="w-form">
            <div className="vertical-form">
              <div className="input-wrapper">
                <input
                  type="text"
                  value={projectName}
                  className="vertical-input w-input"
                  placeholder="Название проекта"
                  onChange={(event) => setProjectName(event.target.value)}
                />
                <div className="input-icon">{/* <PermIdentityIcon /> */}</div>
              </div>
              <div className="button-wrapper">
                <button
                  type="submit"
                  onClick={closeEditMode}
                  className="button primary "
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className="text grey">Название проекта</span>
          <div className="row" onClick={openEditMode}>
            <div className="col min vertical-center">
              <h1 className="h1">{props.projectName}</h1>
            </div>
            <div className="col min vertical-center">
              <button className=" button small">Редактировать</button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
