import React, { useState, useRef, useEffect } from "react";
import TeamMate from "./TeamMate";
import RecommendedUser from "./RecommendedUser";
import rest from "../../../http/axios";

//UI
import Slide from "@material-ui/core/Slide";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// ICONS
import SearchIcon from "@material-ui/icons/Search";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

function transliterate(text) {
  var engToRusMap = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "yo",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "kh",
    ц: "ts",
    ч: "ch",
    ш: "sh",
    щ: "shch",
    ъ: "",
    ы: "y",
    ь: "",
    э: "e",
    ю: "yu",
    я: "ya",
    a: "а",
    b: "б",
    c: "к",
    d: "д",
    e: "е",
    f: "ф",
    g: "г",
    h: "х",
    i: "и",
    j: "дж",
    k: "к",
    l: "л",
    m: "м",
    n: "н",
    o: "о",
    p: "п",
    q: "к",
    r: "р",
    s: "с",
    t: "т",
    u: "у",
    v: "в",
    w: "в",
    x: "кс",
    y: "й",
    z: "з",
  };

  return text.replace(/[а-яёa-z]/g, function (char) {
    return engToRusMap[char] || char;
  });
}

export default function Users(props) {
  const { projectId, showSnackBar } = props;

  const [team, setTeam] = useState([]); // переменная, которая содержит полный состав команды
  const [filteredTeam, setFilteredTeam] = useState([]); // переменная, в которую фильтрую, при поиске людей
  const [recommendedUsers, setReconmendedUsers] = useState([]); // пользователи, которых можно пригласить в проект
  const [filteredRecommendedUsers, setFilteredRecommendedUsers] = useState([]); // фильтрованный список рекомендованных пользователей

  const [inputSearch, setInputSearch] = useState(""); // соновное input поле, используется для фильтрации списка пользователей
  const [inputEmail, setInputEmail] = useState(""); // поле в модальном окне, которое отправляется на сервер
  const [showModal, setShowModal] = useState(false);
  const [role, setRole] = useState("viewer");

  const inputMailRef = useRef();

  const handleRoleChage = (event) => {
    setRole(event.target.value);
  };

  // Загружаю список пользователей проекта
  useEffect(() => {
    loadProjectUsers();
  }, []);

  // При открытии модального окна фокусируюсь на поле ввода email
  useEffect(() => {
    if (inputMailRef.current !== undefined && inputMailRef.current !== null) {
      inputMailRef.current.focus();
    }
  }, [showModal]);

  // При изменении поля ввода email фильтрую список пользователей
  useEffect(() => {
    filterUsers(inputSearch);
  }, [inputSearch]);

  const filterUsers = (searchString) => {
    const baseSearch = searchString.toLowerCase();
    let translitSearch = transliterate(baseSearch);

    if (baseSearch !== "") {
      let filterTeam = team.filter((user) => {
        let userString =
          user.userName.toLowerCase() + "/" + user.email.toLowerCase();

        if (
          userString.includes(baseSearch) ||
          userString.includes(translitSearch)
        ) {
          return user;
        }
      });
      setFilteredTeam(filterTeam);

      let filterRecommendedUsers = recommendedUsers.filter((user) => {
        let userString =
          user.userName.toLowerCase() + "/" + user.email.toLowerCase();

        if (
          userString.includes(baseSearch) ||
          userString.includes(translitSearch)
        ) {
          return user;
        }
      });
      console.log(filterRecommendedUsers);
      setFilteredRecommendedUsers(filterRecommendedUsers);
    } else {
      setFilteredRecommendedUsers(recommendedUsers);
    }
  };

  function loadProjectUsers() {
    rest.get(`/load_project_users/${projectId}`).then((response) => {
      let rsp = response.data;
      setTeam(rsp.team);
      setFilteredTeam(rsp.team);
      setReconmendedUsers(rsp.recommendedUsers);
      setFilteredRecommendedUsers(rsp.recommendedUsers);
    });
  }

  function openInviteModal(email = "") {
    if (email !== "") {
      setInputEmail(email);
    }
    setShowModal(true);
  }

  function closeInviteModal() {
    setShowModal(false);
    setInputEmail("");
    setRole("viewer");
  }

  function sendInviteToProject() {
    if (inputEmail.length > 0) {
      rest
        .post("/send_project_invitation", {
          userEmail: inputEmail,
          userRole: role,
          projectId: props.projectId,
        })
        .then((response) => {
          showSnackBar(response.data);
        })

        .catch((error) => {
          showSnackBar(error.request.response, "error");
        });
      closeInviteModal();
    }
  }

  function removeUser(user) {
    rest
      .post("/unsubscribe_user_on_project", {
        userId: user.id,
        projectId: projectId,
      })
      .then((response) => {
        let rsp = response.data;
        let updatedTeam = filteredTeam.filter(
          (member) => member.id !== user.id
        );
        if (rsp.redirect == true) {
          window.location.href = "/";
        } else {
          setFilteredTeam(updatedTeam);
          showSnackBar(rsp.message);
        }
      });
  }

  function changeUserRole(user, role) {
    rest
      .post("/change_user_role_in_project", {
        userId: user.id,
        projectId: projectId,
        projectRole: role,
      })
      .then((response) => {
        const rsp = response.data;
        let updatedTeam = filteredTeam.map((member) => {
          if (member.id === user.id) {
            member.projectRole = role;
            return member;
          } else {
            return member;
          }
        });
        setFilteredTeam(updatedTeam);

        showSnackBar(rsp.message);
      });
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <h1 className="h1">Найти участника</h1>
        <div className="row">
          <div className="col vertical-center">
            <div className="input-wrapper">
              <input
                type="text"
                className="input w-input"
                placeholder="Email пользователя"
                onChange={(event) => {
                  setInputSearch(event.target.value);
                }}
              />

              <div className="input-icon-wrapper">
                <SearchIcon />
              </div>
            </div>
          </div>
          <div className="col min vertical-center">
            <button
              className="button primary"
              onClick={() => openInviteModal(inputSearch)}
            >
              Пригласить
            </button>
          </div>
        </div>
      </div>

      <div className="content-wrapper">
        <h1 className="h1">Команда проекта</h1>
        {filteredTeam.length > 0 ? (
          filteredTeam.map((user) => (
            <TeamMate
              key={user.id}
              user={user}
              removeUserEvent={removeUser}
              changeUserRoleEvent={changeUserRole}
            />
          ))
        ) : (
          <p className="text">Пользователь не найден...</p>
        )}
      </div>

      {filteredRecommendedUsers.length > 0 ? (
        <div className="content-wrapper">
          <h1 className="h1">Можно еще пригласить в проект</h1>
          {filteredRecommendedUsers.map((user) => (
            <RecommendedUser
              key={user.id}
              user={user}
              openInviteModal={openInviteModal}
            />
          ))}
        </div>
      ) : null}

      {showModal ? (
        <React.Fragment>
          <div className="modal-cover" onClick={closeInviteModal} />
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Пригласить участника</h1>
                </div>
                <div className="modal-content">
                  <div className="content-wrapper">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        ref={inputMailRef}
                        value={inputEmail}
                        className="input w-input"
                        placeholder="Email пользователя"
                        onChange={(event) => setInputEmail(event.target.value)}
                      />
                      <div className="input-icon-wrapper">
                        <SearchIcon />
                      </div>
                    </div>
                    <div className="row">
                      <RadioGroup
                        style={{ marginTop: "30px" }}
                        aria-label="gender"
                        value={role}
                        onChange={handleRoleChage}
                      >
                        <span className="text grey">Права пользователя</span>
                        <FormControlLabel
                          value="viewer"
                          control={<Radio color="primary" />}
                          label="Читатель: Только комментарии"
                        />
                        <FormControlLabel
                          value="admin"
                          control={<Radio color="secondary" />}
                          label="Админ: Возможность редактирования"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="button" onClick={closeInviteModal}>
                    Закрыть
                  </button>
                  <button
                    className="button primary"
                    onClick={sendInviteToProject}
                  >
                    <MailOutlineIcon />
                    &#8194;Отправить приглашение
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
