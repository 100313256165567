import React, { useContext, useState } from "react";
import AppContext from "../../Context/AppContext";
import rest from "../../http/axios";

export default function ExitProject(props) {
  const { projectId } = props;
  const { currentUser } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);

  const removeUser = () => {
    console.log(currentUser);
    rest
      .post("/unsubscribe_user_on_project", {
        userId: currentUser.id,
        projectId: projectId,
      })
      .then((response) => {
        let rsp = response.data;
        window.location.href = "/";
      });
  };

  return (
    <React.Fragment>
      <h1 className="h1">Выйти из проекта</h1>
      <p className="text grey small">
        Когда вы выйдите из проекта, вам более не будут доступны задачи и
        уведомления на ней. Чтобы повторно получить доступ к доске, вам
        потребуется приглашение от одного из ее участников
      </p>
      <button
        className="button danger outline"
        onClick={() => {
          setShowModal(true);
          //   removeUser();
        }}
      >
        Выйти из проекта
      </button>
      {showModal ? (
        <React.Fragment>
          <div className="modal-cover"></div>
          <div className="modal-wrapper">
            <div className="simple-modal">
              <div className="modal-header">
                <h1 className="h1">Выйти из проекта</h1>
              </div>
              <div className="modal-content">
                <p className="text">
                  Когда вы выйдите из проекта, вам более не будут доступны
                  задачи и уведомления на ней. Чтобы повторно получить доступ к
                  доске, вам потребуется приглашение от одного из ее участников
                </p>

                <div className="modal-footer">
                  <button
                    className="button outline"
                    onClick={() => {
                      setShowModal(false);
                    }}
                  >
                    Отмена
                  </button>
                  <button
                    className="button danger"
                    onClick={() => {
                      removeUser();
                    }}
                  >
                    Согласен выйти из проекта
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
