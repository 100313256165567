import React, { useState, useEffect, useContext } from "react";
import rest from "../../../http/axios";

import ProjectViewContext from "../../../Context/ProjectViewContext";

// UI
import TextField from "@material-ui/core/TextField";
// ICONS
import DeleteIcon from "@material-ui/icons/Delete";

export default function LabelEditor(props) {
  const {
    labelsList,
    editedLabel,
    projectId,
    updateLabelsList,
    finishEditLabelMode,
  } = props;

  const { adoptLabelPropsEvent } = useContext(ProjectViewContext);

  const [labelId, setLabelId] = useState(editedLabel.id);
  const [labelName, setLabelName] = useState(editedLabel.name);
  const [labelColor, setLabelColor] = useState(editedLabel.color);

  const [labelColors, setLabelColors] = useState([
    { id: 0, color: "dark-grey", selected: "selected" },
    { id: 2, color: "red", selected: "" },
    { id: 3, color: "blue", selected: "" },
    { id: 4, color: "green", selected: "" },
    { id: 5, color: "orange", selected: "" },
    { id: 6, color: "violet", selected: "" },
    { id: 7, color: "grey", selected: "" },
    { id: 8, color: "dark-red", selected: "" },
    { id: 9, color: "dark-blue", selected: "" },
    { id: 10, color: "dark-green", selected: "" },
    { id: 11, color: "dark-orange", selected: "" },
    { id: 12, color: "dark-violet", selected: "" },
    { id: 13, color: "electric-grey", selected: "" },
    { id: 14, color: "electric-red", selected: "" },
    { id: 15, color: "electric-blue", selected: "" },
    { id: 16, color: "electric-green", selected: "" },
    { id: 17, color: "electric-orange", selected: "" },
    { id: 18, color: "electric-violet", selected: "" },
  ]);

  useEffect(() => {
    if (labelColor !== "dark-grey") {
      setLabelColors(
        labelColors.map((item) => {
          if (item.color === labelColor) {
            item.selected = "selected";
          } else {
            item.selected = "";
          }
          return item;
        })
      );
    }
  }, []);

  function changeColor(event) {
    let targetId = parseInt(event.target.id);
    let targetValue = event.target.value;
    setLabelColors(
      labelColors.map((item) => {
        if (item.id === targetId) {
          item.selected = "selected";
        } else {
          item.selected = "";
        }
        return item;
      })
    );

    setLabelColor(targetValue);
  }

  function saveEditedLabel() {
    if (editedLabel.id === 0) {
      rest
        .post("/add_label", {
          projectId: projectId,
          labelName: labelName,
          labelColor: labelColor,
        })
        .then((response) => {
          let rsp = response.data;
          let newList = [
            ...labelsList,
            { id: rsp.id, name: labelName, color: labelColor, selected: "" },
          ];
          updateLabelsList(newList, { reason: "add", labelId: rsp.id });
        });
    } else if (labelId > 0) {
      rest
        .post("/update_label", {
          projectId: projectId,
          labelId: labelId,
          labelName: labelName,
          labelColor: labelColor,
        })
        .then(() => {
          // Обновляем информацию в списке
          let newList = labelsList.map((item) => {
            if (item.id === labelId) {
              return {
                id: item.id,
                name: labelName,
                color: labelColor,
                selected: item.selected,
              };
            } else {
              return item;
            }
          });
          // Причину указываю, чтобы в родительском компоненте выполнять различную логику на карточке в LabelView
          updateLabelsList(newList, { reason: "edit", labelId: labelId });

          // Обновляем информацию на доске
          adoptLabelPropsEvent({
            labelId: labelId,
            newLabel: {
              id: labelId,
              name: labelName,
              color: labelColor,
            },
          });
        });
    }

    finishEditLabelMode();
  }

  function removeEditedLabel() {
    if (labelId !== 0) {
      let newList = labelsList.filter((item) => item.id !== labelId);
      // Причину указываю, чтобы в родительском компоненте выполнять различную логику на карточке в LabelView
      updateLabelsList(newList, { reason: "delete", labelId: labelId });

      rest
        .post("/remove_label", {
          labelId: labelId,
          projectId: projectId,
        })
        .then((response) => {
          let rsp = response.data;
        });

      finishEditLabelMode();

      adoptLabelPropsEvent({
        labelId: labelId,
        newLabel: {
          id: null,
          name: "",
          color: "",
        },
      });
    }
  }

  return (
    <div className="modal-content">
      <div className="row" style={{ marginBottom: "20px" }}>
        <TextField
          label="Новый ярлык"
          fullWidth
          inputProps={{ autoFocus: true }}
          defaultValue={labelName}
          onChange={(event) => setLabelName(event.target.value)}
        />
      </div>

      <div className="color-container" onChange={(event) => changeColor(event)}>
        {labelColors.map((item) => (
          <label className={`color-wrapper ${item.selected}`} key={item.id}>
            <input
              type="radio"
              id={item.id}
              name="selectColor"
              className="hidden-radio"
              value={item.color}
            />
            <div className={`color-picker ${item.color}`} />
          </label>
        ))}
      </div>

      {labelId > 0 ? (
        <div className="button-wrapper left">
          <button
            className="button danger small outline"
            onClick={() => removeEditedLabel()}
          >
            <DeleteIcon />
            Удалить
          </button>
        </div>
      ) : null}
      <div className="button-wrapper">
        <button className="button" onClick={finishEditLabelMode}>
          Назад
        </button>
        <button className="button primary" onClick={saveEditedLabel}>
          Сохранить
        </button>
      </div>
    </div>
  );
}
