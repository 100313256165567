import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import TopMenu from "../TopMenu/TopMenu";
import rest from "../../../http/axios";
import Chart from "./Chart";
import Checkbox from "./Checkbox";
import { sendEvent } from "../../../UsefullFunctions";

// UI
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function HistogramView(props) {
  // исходные данные, загруженные с сервера
  const [sourceData, setSourceData] = useState([]);
  // подготовленные данные для отрисовки графика
  const [chartData, setChartData] = useState([]);
  // используется для быстрого доступа к данным колонки по id
  const [columnDataDict, setColumnDataDict] = useState();
  // используется для выпадающего списка с колонками
  const [columnDataArr, setColumnDataArr] = useState([]);
  // длинна самой длинной задачи в часах для отрисовки графика
  const [longestTaskInHours, setLongestTaskInHours] = useState(0);
  // данные проекта (название, картинка, роль пользователя) для TopMenu
  const [projectData, setProjectData] = useState({
    projectName: "",
    projectPic: "",
    userRole: "",
  });
  // текущая колонка из правого меню
  const [selectedColumnId, setSelectedColumnId] = useState(0);
  // текущая страница загружаемых данных
  const [pageNum, setPageNum] = useState(0);
  const [enableLoadOnScroll, setEnableLoadOnScroll] = useState(true);

  const chartContainerRef = useRef();

  const { projectId } = useParams();
  const barColors = [
    "bar-color-0",
    "bar-color-1",
    "bar-color-2",
    "bar-color-3",
    "bar-color-4",
    "bar-color-5",
    "bar-color-6",
    "bar-color-7",
    "bar-color-8",
    "bar-color-9",
    "bar-color-10",
    "bar-color-11",
    "bar-color-12",
    "bar-color-13",
    "bar-color-14",
    "bar-color-15",
  ];

  // LOADING BASIC PROJECT DATA
  useEffect(() => {
    sendEvent("view", "histogram", "view_histogram", 1);
    rest.get("/load_project_basics/" + projectId).then((response) => {
      const resp = response.data;
      let columns = {};
      resp.columns.map((column, index) => {
        let color = barColors[0];
        barColors.shift();

        columns[column.id] = {
          name: column.name,
          orderId: column.orderId,
          color: color,
          show: true,
        };
      });

      setColumnDataDict(columns);
      setColumnDataArr(resp.columns);
      setSelectedColumnId(resp.columns[0].id);
      setProjectData({
        projectName: resp.projectName,
        projectPic: resp.projectPic,
        userRole: resp.userRole,
      });
    });
  }, []);

  // функция управлет отображением колонок в графике
  function updateShowColumnSettings(columnId, show) {
    const newColumns = { ...columnDataDict };
    newColumns[columnId]["show"] = show;
    setColumnDataDict(newColumns);
  }

  function prepareDataForUsage(data, localPageNum) {
    console.log(columnDataDict);
    let localLongestTask = 0;

    const result = Object.entries(data).map(([key, value]) => {
      const totalTime = value.chart.reduce((sum, i) => {
        if (columnDataDict[i.columnId]["show"] === true) {
          return sum + i.accTimeInColumn;
        }
        return sum;
      }, 0);

      if (totalTime > localLongestTask) {
        localLongestTask = totalTime;
      }

      const barsData = value.chart.reduce((acc, item) => {
        if (columnDataDict[item.columnId]["show"] === true) {
          const percentage = (item.accTimeInColumn / totalTime) * 100; // calculate percentage
          const formattedPercentage = percentage.toFixed(0); // format percentage to 2 decimal places
          const percentageValue = formattedPercentage + "%"; // add percent sign to the end

          acc.push({
            name: columnDataDict[item.columnId]["name"],
            percentageValue,
            cntTimesInColumn: item.cntTimesInColumn,
            absTimeInHours: item.accTimeInColumn / 60 / 60,
            color: columnDataDict[item.columnId]["color"],
          });
        }
        return acc;
      }, []);

      return {
        taskName: value.name,
        label: value.label,
        totalTimeInHours: totalTime / 60 / 60,
        barsData,
      };
    });

    if (localPageNum === 0) {
      setChartData(result);
    } else {
      setChartData([...chartData, ...result]);
    }
    if (localLongestTask / 3600 > longestTaskInHours) {
      setLongestTaskInHours(localLongestTask / 3600);
    }
    if (result.length === 20) {
      setEnableLoadOnScroll(true);
    }
  }

  function loadData(urlFilter, usePageNumForRequest = true) {
    console.log("LOADING DATA");
    let localPageNum = pageNum;
    if (usePageNumForRequest === false) {
      localPageNum = 0;
      setLongestTaskInHours(0);
    }
    rest
      .get(
        `/load_column_page_chart_data/${projectId}/${selectedColumnId}/${localPageNum}?${urlFilter}`
      )
      .then((response) => {
        const data = response.data;
        if (localPageNum === 0) {
          setSourceData(data);
        } else {
          setSourceData({ ...sourceData, ...data });
        }
        prepareDataForUsage(data, localPageNum);
      });
  }

  // LOADING ARRAY DATA TO RENDER TASK CHART
  useEffect(() => {
    if (columnDataDict === undefined) return;
    if (Object.keys(columnDataDict).length === 0) return;
    let urlParams = window.location.search;

    loadData(urlParams);
  }, [selectedColumnId, pageNum]);

  // REDRAWING CHARTS ON COLUMN SETTINGS CHANGE
  useEffect(() => {
    prepareDataForUsage(sourceData, 0);
  }, [columnDataDict]);

  // CALCULATING AVARAGE NUBERS
  useEffect(() => {
    const data = chartData.map((obj) => obj.totalTimeInHours).flat();

    // Sort the array in ascending order
    data.sort((a, b) => a - b);

    let median = NaN;
    if (data.length % 2) {
      median = data[Math.floor(data.length / 2)];
    } else {
      median = (data[data.length / 2] + data[data.length / 2 - 1]) / 2;
    }

    // Calculate the sum of all the absTimeInHours values
    const sum = data.reduce((acc, curr) => acc + curr, 0);

    // Calculate the average by dividing the sum by the length of the array
    const average = sum / data.length;

    // console.log("MEDIAN: ", median / 24, "AVERAGE: ", average / 24);
  }, [chartData, columnDataDict]);

  function handleScroll(event) {
    const { scrollTop, clientHeight, scrollHeight } = chartContainerRef.current;

    if (scrollTop + clientHeight + 200 > scrollHeight) {
      if (enableLoadOnScroll == false) {
        return;
      }
      setEnableLoadOnScroll(false);
      setPageNum(pageNum + 1); // установка новой страницы автоматом запускает loadData()
    }
  }

  function handleChangeRadio(event) {
    setPageNum(0); // установка новой страницы автоматом запускает loadData()
    setLongestTaskInHours(0);
    setSelectedColumnId(parseInt(event.target.value));
    setEnableLoadOnScroll(true);
  }

  return (
    <div className="absolute-container">
      {/* <TopMenu
        projectId={projectId}
        projectName={projectData.projectName}
        projectPic={projectData.projectPic}
        loadData={loadData}
        userRole={projectData.userRole}
        routeToRedirect={"histogram"}
      /> */}

      <div className="settings-container">
        <div
          className="col col-8 scrollable mobile-full-width"
          ref={chartContainerRef}
          onScroll={(event) => {
            handleScroll(event);
          }}
        >
          <div className="chart-container">
            <div className="row">
              <div className="col col-3">
                <h2 className="h2">Задача</h2>
              </div>
              <div className="col col-1">
                <h2 className="h2">Время</h2>
              </div>
              <div className="col col-2">
                <h2 className="h2">Общее время</h2>
              </div>
              <div className="col col-2">
                <h2 className="h2">Время в колонке</h2>
              </div>
            </div>
            {chartData.map((row, index) => (
              <div className="row chart-row" key={index}>
                <div
                  className="col col-3 mobile-full-width"
                  style={{ paddingRight: "10px" }}
                >
                  <span className={`label ${row.label.color}`}>
                    {row.label.name}
                  </span>
                  <p className="text">{row.taskName}</p>
                </div>
                <div className="col col-1 mobile-full-width">
                  {row.totalTimeInHours > 24 ? (
                    <span className="text bold">
                      {(row.totalTimeInHours / 24).toFixed(1)} д.
                    </span>
                  ) : (
                    <span className="text bold">
                      {(row.totalTimeInHours / 1).toFixed(1)} ч.
                    </span>
                  )}
                </div>
                <div className="col col-2 mobile-full-width">
                  <span
                    className={`bar thin`}
                    style={{
                      width:
                        (row.totalTimeInHours / longestTaskInHours) * 100 + "%",
                    }}
                  ></span>
                </div>
                <div className="col col-4 mobile-full-width">
                  <Chart row={row} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col col-2 mobile-full-width">
          <h2 className="h2">Колонка</h2>
          <RadioGroup
            aria-label="sort"
            name="sort"
            value={selectedColumnId}
            onChange={(event) => handleChangeRadio(event)}
          >
            {columnDataArr.map((column, index) => (
              <FormControlLabel
                value={column.id}
                control={<Radio />}
                label={column.name}
                key={index}
              />
            ))}
          </RadioGroup>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span className="text small bold">Какие статусы показывать</span>
          <div className="chart-history-container">
            {columnDataArr.map((data, index) => (
              <Checkbox
                data={columnDataDict[data.id]}
                id={data.id}
                key={data.id}
                updateShowColumnSettingsEvent={updateShowColumnSettings}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
