import React, { useState, useEffect } from "react";

function SmartDiv({ onClick, onLongClick, children, ...restProps }) {
  const [clickDuration, setClickDuration] = useState(0);

  let startTimestamp = 0;
  let onPressStatus = false;
  let timer = null;

  const handleTouchStart = () => {
    onPressStatus = true;
    startTimestamp = Date.now();
    timer = setTimeout(() => {
      handleClickAction();
    }, 500);
  };

  const handleTouchEnd = () => {
    clearTimeout(timer);
    if (onPressStatus === true) {
      handleClickAction();
    }
  };

  const handleTouchMove = () => {
    clearTimeout(timer);
    onPressStatus = false;
  };

  const handleClickAction = () => {
    let finishTimestamp = Date.now();
    setClickDuration(finishTimestamp - startTimestamp);
    onPressStatus = false;
  };

  useEffect(() => {
    if (clickDuration > 0) {
      if (clickDuration >= 500) {
        if (onLongClick) {
          if ("vibrate" in navigator) {
            // Vibrate for 50 milliseconds
            navigator.vibrate(50);
          }
          onLongClick();
        }
      } else {
        if (onClick) {
          onClick();
        }
      }
    }
  }, [clickDuration]);

  return (
    <div
      onMouseDown={handleTouchStart}
      onMouseUp={handleTouchEnd}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      {...restProps}
    >
      {children}
    </div>
  );
}

export default SmartDiv;
