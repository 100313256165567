import React, { useState } from "react";
import { format } from "date-fns";
import rest from "../../http/axios";
import { sendEvent } from "../../UsefullFunctions";

// MATERIAL
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Avatar from "@material-ui/core/Avatar";

//UI
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";

export default function Changes(props) {
  const { dateFrom, dateOfUpdate, taskId, lastChange } = props;
  const [modalShow, setModalShow] = useState(false);
  const [changesData, setChangesData] = useState([]); // [userName, change, dateTime]

  function showModal(e) {
    e.preventDefault();

    sendEvent("view", "task", "view_hiistory", "");
    rest
      .get(`/load_task_changes/${taskId}`)
      .then((response) => {
        setChangesData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    setModalShow(true);
  }
  function closeModal() {
    setModalShow(false);
  }

  return (
    <div className={`content-wrapper`}>
      <div className="row">
        <div className="col min vertical-center">
          <h2 className="h2">Информация</h2>
        </div>
        <div className="col min vertical-center">
          <button className={`button small`} onClick={showModal}>
            Посмотреть историю
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col min">
          <ul className="text">
            <li>
              Дата начала:&nbsp;
              <strong>{format(dateFrom, "dd.MM.yyyy")}</strong>
            </li>
            <li>
              Дата переноса в колонку:&nbsp;
              <strong>{format(dateOfUpdate, "dd.MM.yyyy")}</strong>
            </li>
            <li>
              Изменение:&nbsp;
              <strong className="text break-word">{lastChange}</strong>
            </li>
          </ul>
        </div>
      </div>

      {modalShow === true ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={modalShow} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">История изменений:</h1>
                </div>
                <div className="modal-content">
                  <Timeline align="left">
                    {changesData.map((item, index) => (
                      // minHeight: 'auto' это костыль для нормализации работы элемента TimelineItem, без него minHeight расценивается как height
                      <TimelineItem style={{ minHeight: "auto" }} key={index}>
                        <TimelineOppositeContent>
                          <span className="text">{item.dateTime}</span>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot>
                            <Tooltip
                              title={item.userName}
                              placement="bottom"
                              style
                              key={index}
                            >
                              <Avatar
                                alt={item.userName}
                                src={item.userPic}
                                style={{ height: 30, width: 30 }}
                              />
                            </Tooltip>
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <span className="text"> {item.change}</span>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </div>
  );
}
