import React from "react";
// import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

export default function TaskViewSkeleton(props) {
  const { theme } = props;
  return (
    <div className="modal-activity">
      <div className={`modal-header ${theme}`}>
        <Skeleton variant="text" height={30} />
        <Skeleton variant="text" height={30} />

        <div className="row">
          {/* COLOR */}
          <div className="col min" style={{ marginRight: 10 }}>
            <Skeleton variant="text" width={140} height={20} />
          </div>
          <div className="col min" style={{ marginRight: 10 }}>
            <Skeleton variant="text" width={140} height={20} />
          </div>
        </div>

        <div className="row">
          <div className="col min" style={{ marginRight: 10 }}>
            <Skeleton variant="circle" width={30} height={30} />
          </div>
          <div className="col min">
            <Skeleton variant="circle" width={30} height={30} />
          </div>
        </div>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col col-6">
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={100} />
          </div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
}
