import React, { useState, useEffect } from "react";
import rest from "../../../http/axios";

export default function ProjectSnapshots(props) {
  const { closeModal, projectId, loadSnapshotOnDesk } = props;

  const [snapshots, setSnapshots] = useState([]);
  const [selectedSnapshot, setSelectedSnapshot] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    rest.get(`/get_snapshots_list/${projectId}`).then((response) => {
      let rsp = response.data;

      if (rsp.snapshots) {
        let snapData = rsp.snapshots.map((item) => {
          item.active = "";
          return item;
        });
        setSnapshots(snapData);
      }
    });
  }, []);

  function changeRadio(event) {
    let targetId = parseInt(event.target.id);

    setSnapshots(
      snapshots.map((item) => {
        if (item.id === targetId) {
          item.active = "selected";
        } else {
          item.active = "";
        }
        return item;
      })
    );

    setSelectedSnapshot({
      id: event.target.id,
      name: event.target.value,
    });
  }

  const saveSnapshot = () => {
    setLoadingStatus(true);
    rest.post(`/add_snapshot`, { projectId: projectId }).then((response) => {
      let rsp = response.data;
      if (rsp.snapshot.action == "add") {
        setSnapshots([
          {
            id: rsp.snapshot.id,
            name: rsp.snapshot.name,
            active: "",
          },
          ...snapshots,
        ]);
      }
      setLoadingStatus(false);
    });
  };

  const loadSnapshot = () => {
    rest
      .get(`/load_snapshot/${projectId}/${selectedSnapshot.id}`)
      .then((response) => {
        let rsp = response.data;
        loadSnapshotOnDesk(rsp.snapshot);
      });
    closeModal();
  };

  return (
    <React.Fragment>
      <div className="top-menu-dropdown">
        <div className="modal-header">
          <div className="row wide">
            <div className="col">
              <span className="text small grey">
                Снимок хранит расположение задач и их статус и участников
              </span>
            </div>
            <div className="col">
              <div className="button-wrapper right">
                <button
                  className="button sky outline small"
                  onClick={() => {
                    saveSnapshot();
                  }}
                >
                  Сделать снимок
                </button>
                {loadingStatus ? (
                  <div className="lds-ripple">
                    <div style={{ border: "2px solid #2485da" }}></div>
                    <div style={{ border: "2px solid #2485da" }}></div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-content" onChange={changeRadio}>
          <h1 className="h1">Снимки доски</h1>
          {snapshots.length === 0 ? (
            <span className="text small grey">
              Пока никто не добавил снимок...
            </span>
          ) : null}
          {snapshots.map((item) => (
            <label className={`select-item ${item.active}`} key={item.id}>
              <input
                type="radio"
                id={item.id}
                name="moveToColumn"
                className="hidden-radio"
                value={item.name}
              />
              <span className="w-form-label">{item.name}</span>
            </label>
          ))}
        </div>

        <div className="modal-footer">
          <button
            className="button"
            onClick={() => {
              closeModal();
            }}
          >
            Отмена
          </button>
          <button
            className="button primary"
            onClick={() => {
              loadSnapshot();
            }}
          >
            Выбрать
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
