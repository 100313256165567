import React, { useRef, useEffect, useState, useContext } from "react";
import ProjectViewContext from "../../../Context/ProjectViewContext";

import { useNavigate } from "react-router-dom";
import ProjectSnapshots from "./ProjectSnapshots";
import { MdClose } from "react-icons/md";
import { TbCapture } from "react-icons/tb";

export default function ProjectName(props) {
  const { projectName, projectPic, userRole, projectId, theme } = props;
  const { loadProjectFromSnapshot, loadProject } = useContext(
    ProjectViewContext
  );

  const projectNameContainer = useRef();
  const navigate = useNavigate();
  // Определяет сколько символов Названия проекта выводить на экран
  const [projectNameLenght, setProjectNameLenght] = useState(0);

  const [showSnapshotsModal, setShowSnapshotsModal] = useState(false); // Показывать ли модальное окно со снимками
  const [showSnapshotNavigation, setShowSnapshotNavigation] = useState(true); // Показывать ли кнопку "Снимок" или "Закрыть снимок"

  useEffect(() => {
    var width = window.innerWidth;
    if (width < 500) {
      setProjectNameLenght(18);
    } else {
      setProjectNameLenght(58);
    }
  }, [projectName]);

  const clickOnHeader = () => {
    if (userRole === "admin" || userRole === "viewer") {
      navigate(`/web_app/project/${projectId}/settings`);
    }
  };

  const closeProjectSnapshots = () => {
    setShowSnapshotsModal(false);
  };

  const loadSnapshotOnDesk = (snapshot) => {
    loadProjectFromSnapshot(snapshot);
    setShowSnapshotsModal(false);
    setShowSnapshotNavigation(false);
  };

  const unloadSnapshotFromDesk = () => {
    loadProject("");
    setShowSnapshotNavigation(true);
  };

  return (
    <React.Fragment>
      <div
        onClick={() => clickOnHeader()}
        className="top-menu-nav start"
        ref={projectNameContainer}
      >
        <div
          className="project-img"
          style={{ backgroundImage: `url(${projectPic})` }}
        />

        <h1 className="active">
          {/* {projectName} */}
          {projectName.length > projectNameLenght
            ? `${projectName.substr(0, projectNameLenght)}...`
            : projectName}
        </h1>
      </div>
      <div
        className="top-menu-nav min desktop-only"
        style={{ position: "relative" }}
      >
        {showSnapshotNavigation ? (
          <button
            className="button"
            onClick={() => {
              setShowSnapshotsModal(true);
            }}
          >
            <TbCapture />
          </button>
        ) : (
          <button
            className="button warning outline small"
            onClick={() => {
              unloadSnapshotFromDesk();
            }}
          >
            Снимок <MdClose />
          </button>
        )}
        {showSnapshotsModal ? (
          <ProjectSnapshots
            projectId={projectId}
            loadSnapshotOnDesk={loadSnapshotOnDesk}
            closeModal={closeProjectSnapshots}
          />
        ) : null}
      </div>
      {showSnapshotsModal ? (
        <div
          className="invisible-cover"
          onClick={() => {
            closeProjectSnapshots();
          }}
        ></div>
      ) : null}
    </React.Fragment>
  );
}
