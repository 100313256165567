import React, { useState } from "react";

// ICONS
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

// UI
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function TeamMate(props) {
  const { user, removeUserEvent, changeUserRoleEvent } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [role, setRole] = useState(user.projectRole);

  function openDetails() {
    setShowDetails(true);
  }
  function closeDetails() {
    setShowDetails(false);
    setRole(user.projectRole);
  }

  function removeUser() {
    removeUserEvent(user);
    closeDetails();
  }

  function changeUserRole() {
    changeUserRoleEvent(user, role);
    closeDetails();
  }

  return (
    <React.Fragment>
      {showDetails ? (
        <div className="subscriber selected" key={user.id}>
          <div className="row">
            <div className="col min">
              <div
                className="message-img"
                style={{ backgroundImage: `url(${user.userPic})` }}
              />
            </div>
            <div className="col">
              <div className="row">
                <span className="text bold">{user.userName}</span>
              </div>
              <div className="row">
                <p className="text small">
                  {user.mobileNumber}&#8194;{user.email}
                </p>
              </div>

              <RadioGroup
                style={{ marginTop: "30px" }}
                aria-label="gender"
                value={role}
                onChange={(event) => setRole(event.target.value)}
              >
                <span className="text grey">Права пользователя</span>
                <FormControlLabel
                  className="text"
                  value="viewer"
                  control={<Radio color="primary" />}
                  label="Читатель: Только комментарии"
                />
                <FormControlLabel
                  className="text"
                  value="admin"
                  control={<Radio color="secondary" />}
                  label="Админ: Возможность редактирования"
                />
              </RadioGroup>
            </div>
            <div className="col min">
              <ExpandLessIcon
                style={{ color: "grey" }}
                onClick={closeDetails}
              />
            </div>
          </div>
          <div className="row">
            <div className="col min vertical-center">
              <DeleteOutlineIcon
                className="icon active red"
                onClick={removeUser}
              />
            </div>
            <div className="col vertical-center">
              <div className="button-wrapper">
                <button className="button" onClick={closeDetails}>
                  Отменить
                </button>
                <button className="button primary" onClick={changeUserRole}>
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="subscriber" key={user.id}>
          <div className="row">
            <div className="col min">
              <div
                className="message-img"
                style={{ backgroundImage: `url(${user.userPic})` }}
              />
            </div>
            <div className="col">
              <div className="row">
                <span className="text bold">{user.userName}</span>
                &#8194;
                <span className="text grey small">
                  {user.projectRole === "admin" ? "Админ" : "Читатель"}
                </span>
              </div>

              <div className="row">
                <span className="text small">
                  {user.email}&#8194;{user.mobileNumber}
                </span>
              </div>
              <div className="row">
                <div className="text small">
                  Последняя активность: {user.lastVisit}
                </div>
              </div>
            </div>
            <div className="col min">
              <ExpandMoreIcon style={{ color: "grey" }} onClick={openDetails} />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
