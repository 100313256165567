import React from "react";

// ICONS
import { MdViewWeek } from "react-icons/md";

export default function TaskColumnReadOnly(props) {
  const { theme } = props;
  return (
    <div className="horizontal-col min">
      <MdViewWeek
        className={`icon standart ${theme}`}
        style={{ marginRight: "5px" }}
      />
      <div>
        <span className={`text ${theme}`}>Колонка:&#8194;</span>
        <strong className={`text bold ${theme}`}>{props.currentColumn}</strong>
      </div>
    </div>
  );
}
