import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rest from "../http/axios";

export default function AcceptProjectInvite(props) {
  const [isValidating, setIsValidating] = useState(true);

  const { token } = useParams();

  const navigate = useNavigate();

  rest
    .get(`/accept_project_invitation/${token}`)
    .then(() => {
      navigate("/web_app", { replace: true });
    })
    .catch((error) => {
      setIsValidating(false);
    });

  return (
    <React.Fragment>
      {isValidating ? (
        <div className="text">Проверка доступности проекта</div>
      ) : (
        <div className="text">У вас нет прав к запрашиваемому проекту</div>
      )}
    </React.Fragment>
  );
}
