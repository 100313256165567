// import { useEventCallback } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import Linkify from "react-linkify";
import { parseHtml } from "../../../UsefullFunctions";
import TipTapEditor from "./TipTapEditor";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";
import VersionSelector from "./VersionSelector";
import { HtmlDiffer } from "html-differ";
import { MdClose } from "react-icons/md";
import Prism from "prismjs";
import "../../../styles/prism.css";

// ICONS
import CloseIcon from "@material-ui/icons/Close";

export default function TaskDescription(props) {
  const {
    theme,
    changeWidgetSettingsEvent,
    setShowSendMessageContainerEvent,
    updateTaskDescription,
    descriptionObj,
  } = props;

  const { taskId, projectId } = useContext(ProjectViewContext);
  const { socket } = useContext(BasicContext);

  const [editMode, setEditMode] = useState(false);
  const [showWidgetNav, setShowWidgetNav] = useState(false); // управляет отображение виджета описания
  const [prevDescription, setPrevDescription] = useState("");
  const [comparedContent, setComparedContent] = useState(""); // объект, который формируется в момент сравнения версий
  const [showComparedContent, setShowComparedContent] = useState(false); // определяет, показывать ли сравнение контента, вместо обычного описания

  const diffOptions = {
    ignoreAttributes: [],
    compareAttributesAsJSON: [],
    ignoreWhitespaces: true,
    ignoreComments: true,
    ignoreEndTags: false,
    ignoreDuplicateAttributes: false,
  };

  useEffect(() => {
    addClassNameToCode();
    let selector = document.querySelector("code");
    if (selector) {
      Prism.highlightElement(selector);
    }
  }, [descriptionObj]);

  function addClassNameToCode() {
    var codeElements = document.querySelectorAll("code");
    // Iterate through each <code> element
    codeElements.forEach(function (el) {
      // Add a class to the current element
      el.classList.add("language-javascript");
    });
  }

  const showEditDescription = () => {
    setEditMode(true);
    setShowWidgetNav(false);
    setPrevDescription(descriptionObj.content);
    setShowSendMessageContainerEvent(false); // скрываю меню отправки сообщения в режиме редактирования
  };

  const closeEditDescription = () => {
    setEditMode(false);
    setShowSendMessageContainerEvent(true); // возвращаю меню отправки сообщения после окончания редактирования
  };

  function saveEditDescription(content, quickSave = false) {
    // По умолчанию значение поля null
    if (content !== null && content !== prevDescription) {
      content = content.trim();
      let payload = {
        projectId: projectId,
        taskId: taskId,
        descriptionObj: {
          // id: descriptionObj.id,
          content: content,
        },
        quickSave: quickSave,
      };

      socket.emit("update_task_description", payload, (data) => {
        updateTaskDescription(data.descriptionObj);
      });
    }
    // Если это не быстрая запись, а по нажатию на кнопку, значит закрываем окно редактирования
    if (quickSave === false) {
      closeEditDescription();
    }
  }

  const undoSaving = () => {
    let newDescriptionObj = {
      ...descriptionObj,
      content: prevDescription,
    };

    let descData = {
      projectId: projectId,
      taskId: taskId,
      descriptionObj: newDescriptionObj,
      quickSave: false,
    };

    socket.emit("update_task_description", descData, (data) => {
      updateTaskDescription(newDescriptionObj);
    });

    closeEditDescription();
  };

  // const createNewVersion = (newVersion) => {
  //   let descData = {
  //     projectId: projectId,
  //     taskId: taskId,
  //     descriptionObj: {
  //       id: null,
  //       version: newVersion,
  //       content: descriptionObj.content,
  //     },
  //   };
  //   socket.emit("create_new_version_in_task_description", descData, (data) => {
  //     if (data.status === "ok") {
  //       updateTaskDescription(data.descriptionObj);
  //     }
  //   });
  // };

  const loadVersion = (versionId) => {
    let payload = {
      projectId: projectId,
      taskId: taskId,
      versionId: versionId,
    };
    socket.emit("load_task_description", payload, (data) => {
      if (data.status === "ok") {
        updateTaskDescription(data.descriptionObj);
      }
    });
  };

  const compareVersions = (versionId) => {
    let payload = {
      projectId: projectId,
      taskId: taskId,
      versionId: versionId,
    };
    socket.emit("load_task_description", payload, (data) => {
      if (data.status === "ok") {
        const htmlDiffer = new HtmlDiffer(diffOptions);
        let diff = htmlDiffer.diffHtml(
          data.descriptionObj.content,
          descriptionObj.content
        );
        let comparedContent = "";
        diff.forEach((element) => {
          if (element.added == true) {
            comparedContent +=
              "<b class='compare-bold'>" + element.value + "</b>";
          } else if (element.removed == true) {
            comparedContent +=
              "<s class='compare-strike'>" + element.value + "</s>";
          } else {
            comparedContent += element.value;
          }
        });
        setComparedContent(comparedContent);
        setShowComparedContent(true);
      }
    });
  };

  const disableVersionsCompare = () => {
    setComparedContent("");
    setShowComparedContent(false);
  };

  return (
    <div className={`content-wrapper ${theme}`}>
      <div className="row">
        {showWidgetNav ? (
          <div className="col min vertical-center">
            <CloseIcon
              className="icon small"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setShowWidgetNav(!showWidgetNav);
              }}
            />
          </div>
        ) : null}

        <div className="col min vertical-center">
          <h2
            className={`h2 active`}
            onClick={() => {
              setShowWidgetNav(!showWidgetNav);
            }}
          >
            Описание
          </h2>
        </div>
        {descriptionObj.version > 0 && showComparedContent == false ? (
          <VersionSelector
            version={descriptionObj.version}
            taskId={taskId}
            // createNewDescriptionVersion={createNewVersion}
            loadDescriptionVersion={loadVersion}
            compareDescriptionVersions={compareVersions}
            theme={theme}
          />
        ) : null}

        {showComparedContent == true ? (
          <div className="col min vertical-center">
            <button
              className="button small warning outline"
              onClick={() => {
                disableVersionsCompare();
              }}
            >
              Отключить сравнение <MdClose />
            </button>
          </div>
        ) : null}

        {editMode === false ? (
          <React.Fragment>
            {showWidgetNav ? (
              <div className="col min vertical-center">
                <button
                  className="button danger outline small"
                  onClick={() => {
                    changeWidgetSettingsEvent("showDescription", false);
                  }}
                >
                  Скрыть раздел
                </button>
              </div>
            ) : (
              <React.Fragment>
                {descriptionObj.editable === true &&
                showComparedContent == false ? (
                  <div className={`col min vertical-center`}>
                    <button
                      className={`button small`}
                      onClick={showEditDescription}
                    >
                      Изменить
                    </button>
                  </div>
                ) : null}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : null}
      </div>
      {editMode == true ? (
        <TipTapEditor
          content={descriptionObj.content}
          saveEditDescriptionEvent={saveEditDescription}
          undoSavingEvent={undoSaving}
          showEditor={editMode}
        />
      ) : (
        <div className="row permit-text-selection">
          <div className="col break-word">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className="link-break-all"
                  target="blank"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {descriptionObj.content !== null && showComparedContent == false
                ? parseHtml(descriptionObj.content)
                : ""}

              {comparedContent != "" && showComparedContent == true
                ? parseHtml(comparedContent)
                : null}
            </Linkify>
          </div>
        </div>
      )}
    </div>
  );
}
