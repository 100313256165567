import axios from "axios";
import React, { useState, useContext, useRef, useEffect } from "react";
import { HOST } from "../AppConfig";
import { useNavigate } from "react-router-dom";
import AppContext from "../Context/AppContext";
import rest from "../http/axios";
import { sendEvent } from "../UsefullFunctions";

import SignUp from "./SignUp";
import RestorePassword from "./RestorePassword";

// UI
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

export default function Login() {
  const { authorized, loginUserEvent } = useContext(AppContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [snackbar, setSnackbar] = useState({
    status: false,
    message: "",
    severity: "success",
  });

  const registerButtonRef = useRef();
  const google = window.google;

  // При загрузке страницы проверяем авторизацию пользователя
  useEffect(() => {
    // если страница загружается с активной авторизацией - делаем редирект на список проектов
    if (authorized == true) {
      navigate("/web_app");
    }
  }, [authorized]);

  // При загрузке страницы инициализируем кнопку Google Login
  useEffect(() => {
    sendEvent("view", "login", "view_login", "");
    try {
      google.accounts.id.initialize({
        client_id:
          "722263928373-n9pdnsbh5g2lc9q52595iqvonv6uh2mp.apps.googleusercontent.com",
        callback: handleGoogleLogin,
      });
      google.accounts.id.renderButton(document.getElementById("google-login"), {
        theme: "outline",
        size: "large",
      });
    } catch (error) {
      console.log("GOOGLE LOGIN ERROR: ", error);
    }
  }, []);

  function handleGoogleLogin(resp) {
    console.log("CREDENTIAL: ", resp.credential);
    axios
      .post(
        `${HOST}/api/login_with_google`,
        { googleToken: resp.credential },
        { withCredentials: true }
      )
      .then(async (response) => {
        let resp = response.data;
        console.log(resp);
        authorizeUser(resp);
      })
      .catch((error) => {
        showSnackbar("Ошибка при авторизации", "error");
        console.log(error);

        // if (error.response.status == 400) {
        //   showSnackbar(error.response.data, "error");
        // } else {
        //   console.log("LOGIN ERROR: ", error);
        // }
      });
  }

  function onChangeEmail(event) {
    setEmail(event.target.value);
  }
  function onChangePassword(event) {
    setPassword(event.target.value);
  }

  // Функция используется для авторизации пользователся в процессе стандартной авторизации
  // а также при регистрации
  function authorizeUser(resp) {
    localStorage.setItem("access_token", resp.accessToken);
    localStorage.setItem("refresh_token", resp.refreshToken);

    // Проверяем наличие переменной в localStorage и если ее нет, делаем дефолтное состояние
    if (!localStorage.getItem("messagesFilter")) {
      localStorage.setItem("messagesFilter", "all");
    }

    showSnackbar(resp.message);

    setTimeout(() => {
      rest.get("/user_info").then((response) => {
        resp = response.data;
        let profileObj = {
          id: resp.id,
          name: resp.firstName,
          default_name: resp.defaultName,
          user_pic: resp.userPic,
          mob_num: resp.mobNum,
          email: resp.email,
          registration_date: resp.registrationDate,
          web_notification: resp.webNotification,
          web_notification_token: "",
          mail_notification: resp.mailNotification,
        };

        if (resp) {
          loginUserEvent(profileObj);
          navigate("/web_app");
        }
      });
    }, 1000);
  }

  function loginFormSubmit(event) {
    event.preventDefault();
    console.log("loggin to: ", `${HOST}/api/login`);
    axios
      .post(
        `${HOST}/api/login`,
        { email: email, password: password },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(async (response) => {
        let resp = response.data;
        authorizeUser(resp);
      })
      .catch((error) => {
        showSnackbar("Ошибка при авторизации", "error");
        console.log(error);
        // if (error.response.status == 400) {
        //   showSnackbar(error.response.data, "error");
        // } else {
        //   showSnackbar(
        //     "Сервер Branzy недоступен. Проверьте соединение Интернет.",
        //     "error"
        //   );
        // }
      });
  }

  function showSnackbar(message, severity) {
    setSnackbar({ status: true, message: message, severity: severity });
  }

  function hideSnackBar() {
    setSnackbar({ status: false, message: "" });
  }

  return (
    <div className="fullscreen">
      <div className="login-wrapper">
        <div className="menu-logo-container">
          <div className="menu-logo"></div>
        </div>
        <div className="login-body">
          <form className="login-form" onSubmit={loginFormSubmit}>
            <h1 className="h1">ВХОД</h1>
            <input
              type="text"
              className="input login-element w-input"
              maxLength={80}
              placeholder="Email"
              onChange={onChangeEmail}
              value={email}
            />
            <input
              type="password"
              className="input login-element w-input"
              maxLength={80}
              placeholder="Пароль"
              onChange={onChangePassword}
              value={password}
              required
            />
            <div className="row">
              <div className="col vertical-center">
                <RestorePassword showSnackbar={showSnackbar} />
              </div>
              <div className="col">
                <input
                  type="submit"
                  value="Войти"
                  className="button primary big"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="button-wrapper center">
          <div id="google-login"></div>
        </div>

        <div className="login-footer">
          <SignUp
            registerButtonRef={registerButtonRef}
            authorizeUserEvent={authorizeUser}
            showSnackbar={showSnackbar}
          />
        </div>
      </div>
      <div className="login-support-wrapper">
        <div className="hero-wrapper big">
          <div className="content-wrapper">
            <p className="hero-heading big">
              <span className="text-span-3">Branzy</span> - мощный и простой
              инструмент, который позволяет эффективно управлять задачами,
              командами и бюджетами
              <span style={{ fontSize: "50" }}> &#9201;</span>
            </p>
            <button
              className="button warning big"
              style={{ marginTop: "50px" }}
              onClick={() => registerButtonRef.current.click()}
            >
              НАЧАТЬ БЕСПЛАТНО
            </button>
          </div>
        </div>
        <div className="hero-wrapper">
          <div className="content-wrapper">
            <p className="hero-heading big">
              ПРЕИМУЩЕСТВА
              <span style={{ fontSize: "50" }}>&#128165;</span>
              <span style={{ fontSize: "50" }}>&#128171;</span>
            </p>
          </div>
          <div className="content-wrapper">
            <p className="text big bold">РЕАКТИВНАЯ ЗАГРУЗКА ДОСКИ</p>
            <p className="text big">
              Мы ценим каждую сэкономленную минуту времени, чтобы вы могли
              сфокусироваться на важном, поэтому сделали загрузку доски
              моментальной, даже если на ней тысячи задач
            </p>
          </div>
          <div className="content-wrapper">
            <p className="text big bold">МГНОВЕННОЕ ОБНОВЛЕНИЕ ДОСКИ И ЗАДАЧ</p>
            <p className="text big">
              Все измненения которые вы внесёте на доске, будут мгновенно
              отображены у других пользователей
            </p>
          </div>
          <div className="content-wrapper">
            <p className="text big bold">ПОМОЩНИК ПО ПОИСКУ ДУБЛЕЙ ЗАДАЧ</p>
            <p className="text big">
              При заведении новой задачи, Branzy сразу проверит наличие схожих
              задач в той же тематике, чтобы вы не тратили время поиск и
              удаление дублирующих задач
            </p>
          </div>
          <div className="content-wrapper">
            <p className="text big bold">УДОБНАЯ РОЛЕВАЯ МОДЕЛЬ</p>
            <p className="text big">
              Мы понимаем, что не каждый участник доски может правильно
              управлять задачами, поэтому мы выдели несколько ролей. Одни
              участники могут польностью управлять задачми, вторые - только
              писать комментарии
            </p>
          </div>
          <div className="content-wrapper">
            <p className="text big bold">ГИБКАЯ РАБОТА С ОПИСАНИЕМ ЗАДАЧИ</p>
            <p className="text big">
              При описании задачи вам доступны инструменты редактирования
              текста, автосохранение каждый 10 секунд, если вы случайно закроете
              страницу и версионирование, чтобы легко отслеживать как менялись
              требования к задаче в процессе работы над ней
            </p>
          </div>
        </div>
        <div className="hero-wrapper">
          <div className="content-wrapper">
            <p className="hero-heading big">
              СТОИМОСТЬ <span style={{ fontSize: "50" }}>💵</span>
            </p>
          </div>

          <div className="content-wrapper">
            <p className="text big">
              Доступ к Branzy предоставляется абсолютно бесплатно, потому что я
              считаю, что эффективное управление задачами должно быть доступно
              каждому
            </p>
          </div>
        </div>
        <div className="hero-wrapper">
          <div className="content-wrapper">
            <p className="hero-heading big">
              ПРО МЕНЯ <span style={{ fontSize: "50" }}>&#9749;</span>
            </p>
          </div>
          <div className="content-wrapper">
            <p className="text big">
              Привет, меня зовут Максим и я создатель Branzy! За свою карьеру я
              вел множесто проектов разной величины - от маленьких лендингов до
              крупных платформ.
            </p>
            <p className="text big">
              За это время я понял, что эффективное управление задачами - это
              залог успеха любого проекта. Поэтому я создал Branzy, как
              максимально простой и удобный инструмент для управления задачами,
              которым с удовольствием делюсь с вами.
            </p>
            <p className="text big">
              В условиях санкций и ухода Trello из России, Branzy станет
              отличной, супер быстрой и мощной альтернативой для вас и вашей
              команды!
            </p>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.status}
        autoHideDuration={4000}
        onClose={hideSnackBar}
      >
        <MuiAlert elevation={6} variant="filled" severity={snackbar.severity}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
