import React, { useState, useEffect, useContext, useRef } from "react";
import rest from "../../../http/axios";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import AppContext from "../../../Context/AppContext";
import BasicContext from "../../../Context/BasicContext";

//ICONS
import LinkIcon from "@material-ui/icons/Link";
import { BsCheck2Circle } from "react-icons/bs";

export default function AddLinkedTaskFrom(props) {
  const {
    parentTaskId,
    setShowModalEvent,
    addLinkedTaskToTaskState,
    showSnackBarEvent,
  } = props;

  const searchInputRef = useRef();

  const { projectId, addTaskToProject } = useContext(ProjectViewContext);
  const { socket } = useContext(BasicContext);
  const { currentUser } = useContext(AppContext);

  const [searchInput, setSearchInput] = useState("");
  const [searchTasks, setSearchTasks] = useState([]);
  const [searchTimer, setSearchTimer] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  function handleSaveButton() {
    if (selectedTask) {
      addLinkBetweenTasks(selectedTask.id);
      setShowModalEvent(false);
    } else {
      showSnackBarEvent("Выберите задачу или создайте новую", "warning");
    }
  }
  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  function addLinkBetweenTasks(linkTaskId) {
    socket.emit(
      "add_link_between_tasks",
      {
        projectId: projectId,
        masterTaskId: parentTaskId,
        slaveTaskId: linkTaskId,
      },
      (callback) => {
        if (callback == undefined) return;
        if (callback.status == "ok") {
          console.log(callback.data);
          addLinkedTaskToTaskState(callback.data);
          showSnackBarEvent("Задача привязана", "success");
        } else if (callback.status == "error") {
          if (callback.code == "409.1") {
            showSnackBarEvent(
              "Вы не можете привязать задачу саму к себе",
              "warning"
            );
          } else if (callback.code == "409.2") {
            showSnackBarEvent("Задача уже привязана", "warning");
          } else {
            showSnackBarEvent("Не удалось привязать задачу", "error");
          }
        }
      }
    );
  }

  const searchTask = (searchText) => {
    if (searchText === "") {
      setSearchTasks([]);
      return;
    }

    // проверяем, начинается ли строка с http или https, если да, то это ссылка
    if (searchText.startsWith("http") || searchText.startsWith("https")) {
      // ищем в ссылке по маске https://domain/web_app/project/6/task/2870 номер проекта и номер задачи
      const urlParams = searchText.split("/");
      const domain = urlParams[2];
      if (
        ["branzy.", "localhost"].some((substring) => domain.includes(substring))
      ) {
        try {
          const linkProjectId = urlParams[5];
          const linkTaskId = urlParams[7];
          rest
            .get(`/simple_search/${linkProjectId}/${linkTaskId}`)
            .then((response) => {
              let resp = response.data;
              setSelectedTask(resp.tasks[0]);
            });
        } catch (error) {
          console.log("Ссылка не подходит для поиска задачи");
        }
      } else {
        showSnackBarEvent("Эта ссылка не похожа на Branzy", "warning");
        searchInputRef.current.value = "";
      }
    } else {
      // если в поле не ссылка, то ищем по описанию
      rest
        .get(`/quick_search/${projectId}/${searchText}`)
        .then((response) => {
          let resp = response.data;
          setSearchTasks(resp.tasks);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (searchInput.length > 2) {
      if (searchTimer) {
        clearTimeout(searchTimer);
        console.log("clearTimeout");
      }
      setSearchTimer(
        setTimeout(() => {
          searchTask(searchInput);
          console.log("running search");
        }, 400)
      );
    } else {
      setSearchTasks([]);
      setSelectedTask(null);
    }
    return () => {
      clearTimeout(searchTimer);
    };
  }, [searchInput]);

  return (
    <React.Fragment>
      <div
        className="modal-cover active"
        onClick={() => setShowModalEvent(false)}
      ></div>
      <div className="modal-wrapper">
        <div className="simple-modal">
          <div className="modal-header">
            <h1 className="h1">Привязать задачу</h1>
          </div>

          <div className="modal-content">
            <div>
              <div className="input-wrapper">
                <input
                  type="text"
                  ref={searchInputRef}
                  className="input w-input"
                  placeholder="Найти задачу по описанию или ссылке"
                  onChange={(event) => setSearchInput(event.target.value)}
                />
                <div className="input-icon-wrapper">
                  <LinkIcon />
                </div>
              </div>
              {searchTasks.length > 0 ? (
                <div className="search-container">
                  <h2 className="h2" style={{ marginLeft: "10px" }}>
                    Быстрый поиск
                  </h2>
                  <ul className="search-list">
                    {searchTasks.map((item, index) => (
                      <li
                        className="search-item"
                        key={item.id}
                        onClick={() => {
                          setSelectedTask(item);
                          setSearchTasks([]);
                          searchInputRef.current.value = "";
                        }}
                      >
                        {item.taskName} - {item.column} ({item.label})
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {selectedTask ? (
                <>
                  <h3 className="h3" style={{ margin: "20px 10px 0px 10px" }}>
                    Выбранная задача
                  </h3>
                  <div className="task">
                    <div className="label">{selectedTask.label}</div>
                    <div className="task-body">
                      <span className="text medium break-word">
                        {selectedTask.taskName}
                      </span>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          <div className="modal-footer">
            <button
              className=" button"
              onClick={() => setShowModalEvent(false)}
            >
              Закрыть
            </button>
            <button
              className="button  primary"
              onClick={() => {
                handleSaveButton();
              }}
            >
              Привязать
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
