import React, { useState, useContext } from "react";
import rest from "../../../../http/axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ProjectViewContext from "../../../../Context/ProjectViewContext";

//UI
import Slide from "@material-ui/core/Slide";
import { BiCopy } from "react-icons/bi";

export default function ColumnSettingsModal(props) {
  const {
    hideColumnSettingsModalEvent,
    saveColumSettingsEvent,
    column,
  } = props;

  const context = useContext(ProjectViewContext);

  const [sortValue, setSortValue] = useState(column.sortOrder.split("_")[0]);
  const [sortDirection, setSortDirection] = useState(
    column.sortOrder.split("_")[1]
  );
  const [showDateOfUpdate, setShowDateOfUpdate] = useState(
    column.showDateOfUpdate
  );
  const [showDateTo, setShowDateTo] = useState(column.showDateTo);
  const [showUsers, setShowUsers] = useState(column.showUsers);
  // Переменные для удаления колонки
  const [deleteColumnValue, setDeleteColumnValue] = useState("");

  const saveColumnSettings = () => {
    let settings = {
      sortOrder: sortValue + "_" + sortDirection,
      showDateOfUpdate: showDateOfUpdate,
      showDateTo: showDateTo,
      showUsers: showUsers,
      columnId: column.column_id,
    };
    saveColumSettingsEvent(settings);
  };

  const deleteColumn = () => {
    if (column.tasks.length === 0) {
      rest
        .post("/remove_column", { columnId: column.column_id })
        .then((response) => {
          context.deleteColumnEvent(column.column_id);
        });
    }
    hideColumnSettingsModalEvent();
  };

  return (
    <React.Fragment>
      <div
        className="modal-cover active"
        onClick={() => {
          hideColumnSettingsModalEvent();
        }}
      ></div>
      <Slide direction="up" in={props.showModal} mountOnEnter unmountOnExit>
        <div className="modal-wrapper">
          <div className="simple-modal">
            <div className="modal-header">
              <h1 className="h1">Колонка {column.name}</h1>
            </div>
            <div className="modal-content">
              <div className="content-wrapper">
                <h2 className="h2">Сортировка по:</h2>
                <RadioGroup
                  aria-label="sort"
                  name="sort"
                  value={sortValue}
                  onChange={(event) => {
                    setSortValue(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="id"
                    control={<Radio />}
                    label="Номеру задачи"
                  />
                  <FormControlLabel
                    value="dateTo"
                    control={<Radio />}
                    label="Планируемой дате готовности"
                  />
                  <FormControlLabel
                    value="dateOfUpdate"
                    control={<Radio />}
                    label="Дате переноса в колонку"
                  />
                  <FormControlLabel
                    value="label"
                    control={<Radio />}
                    label="Ярлыкам"
                  />
                  <FormControlLabel
                    value="files"
                    control={<Radio />}
                    label="Наличию файлов"
                  />
                </RadioGroup>
              </div>

              <div className="content-wrapper">
                <h2 className="h2">Направление сортировки:</h2>
                <RadioGroup
                  aria-label="sort_direction"
                  name="sort_direction"
                  value={sortDirection}
                  onChange={(event) => {
                    setSortDirection(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="asc"
                    control={<Radio />}
                    label="По возрастанию"
                  />
                  <FormControlLabel
                    value="desc"
                    control={<Radio />}
                    label="По убыванию"
                  />
                </RadioGroup>
              </div>

              <div className="content-wrapper">
                <h2 className="h2">Настройки отображения</h2>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showDateOfUpdate"
                      checked={showDateOfUpdate}
                      onChange={() => setShowDateOfUpdate(!showDateOfUpdate)}
                    />
                  }
                  label="Дней с момента переноса в колонку"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showDateTo"
                      checked={showDateTo}
                      onChange={() => setShowDateTo(!showDateTo)}
                    />
                  }
                  label="Дней до реализации задачи"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showDateTo"
                      checked={showUsers}
                      onChange={() => setShowUsers(!showUsers)}
                    />
                  }
                  label="Аватары пользователей"
                />
              </div>

              <div className="content-wrapper">
                <h2 className="h2">Удалить колонку</h2>
                <p className="text small">
                  Чтобы удалить колонку,{" "}
                  {column.tasks.length > 0 ? (
                    <b>уберите из нее все задачи и</b>
                  ) : null}{" "}
                  введите ее название в поле ниже: <b>{column.name}</b>
                  <button
                    className="button small"
                    onClick={() => {
                      navigator.clipboard.writeText(column.name);
                    }}
                  >
                    <BiCopy style={{ marginRight: "5px" }} /> Копировать
                    название
                  </button>
                </p>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="input w-input"
                    placeholder="Название колонки"
                    onChange={(event) =>
                      setDeleteColumnValue(event.target.value)
                    }
                  />
                  <div
                    className="input-icon-wrapper"
                    style={{ color: "rgb(226, 88, 88)" }}
                  >
                    <DeleteOutlineIcon />
                  </div>
                  <div className="button-wrapper center">
                    {deleteColumnValue === column.name ? (
                      <button
                        className=" button danger outline"
                        onClick={() => deleteColumn()}
                      >
                        Удалить
                      </button>
                    ) : (
                      <button disabled className=" button grey outline">
                        Удалить
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className=" button"
                onClick={hideColumnSettingsModalEvent}
              >
                Закрыть
              </button>
              <button className=" button primary" onClick={saveColumnSettings}>
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </Slide>
    </React.Fragment>
  );
}
