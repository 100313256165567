import React, { useState, useEffect } from "react";
import rest from "../../../http/axios";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider, PhotoView } from "react-photo-view";

// ICONS
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

export default function FilesReadOnly(props) {
  const { theme, files, showSnackBar } = props;

  const [images, setImages] = useState([]);

  const reImages = /.+(?:jpg|jpeg|gif|png)/i;

  useEffect(() => {
    const fetchImages = async () => {
      // Clear the images state initially
      setImages([]);

      // Filter out the files that match the regular expression
      const imageFiles = files.filter((item) => item.name.match(reImages));

      // Map the files to an array of promises
      const imagePromises = imageFiles.map(async (item) => {
        try {
          const response = await rest.get(`/view_image/${item.id}`, {
            responseType: "blob",
          });
          const imageUrl = URL.createObjectURL(response.data);
          item.imageUrl = imageUrl;
          return item;
        } catch (e) {
          console.log(e.response.status, e.response.data);
          return null;
        }
      });

      // Wait for all promises to resolve
      const images = await Promise.all(imagePromises);

      // Filter out any null values (failed requests)
      const validImages = images.filter((image) => image !== null);

      // Update the state with the fetched images
      setImages(validImages);
    };

    // Clear images if files array is empty and images array has items
    if (files.length === 0 && images.length > 0) {
      setImages([]);
    } else {
      fetchImages();
    }
  }, [files]);

  function downloadFile(itemId) {
    showSnackBar("Файл загружается");
    let targetFile = files.filter((item) => item.id == itemId)[0];

    rest
      .get(`/download_file/${itemId}`)
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", targetFile.name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        showSnackBar("Ошибка загрузки файла", "error");
        console.log("Ошибка скачивания файла: ", error);
      });
  }

  return (
    <div className={`content-wrapper ${theme}`}>
      <div className="row">
        <div className="col min vertical-center">
          <h2 className="h2">Файлы</h2>
        </div>
      </div>

      <div className="files-container">
        <PhotoProvider>
          {images.map((item) => (
            <div className="file" key={item.id}>
              <PhotoView key={`img_${item.id}`} src={item.imageUrl}>
                <div className="file-body">
                  <img
                    src={item.thumbPath}
                    alt={item.name}
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  />

                  <b className="text">{item.name}</b>
                  <b className="text grey medium">
                    {item.daysFromPublication}д. назад
                  </b>
                </div>
              </PhotoView>
            </div>
          ))}
        </PhotoProvider>
      </div>
      <div className="files-container">
        {files.map((item) =>
          item.name.match(reImages) ? null : (
            <div className="file" key={item.id}>
              <div className="file-body" onClick={() => downloadFile(item.id)}>
                <div
                  className="file-image"
                  style={{ backgroundImage: `url(${item.thumbPath})` }}
                />

                <b className="bold">{item.name}</b>
                <b className="grey medium">
                  {item.daysFromPublication}д. назад
                </b>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
