import React from "react";
import { Route, Navigate } from "react-router-dom";

const ProtectedRoute = ({ authorized, path, element, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      element={authorized ? element : <Navigate to="/login" />}
    />
  );
};

export default ProtectedRoute;
