import axios from "axios";
import React, { useState, useRef } from "react";
import { HOST } from "../AppConfig";

//UI
import Slide from "@material-ui/core/Slide";

export default function RestorePassword(props) {
  const { showSnackbar } = props;
  const inputRef = useRef(null);

  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }
  function closeModal() {
    setShowModal(false);
  }

  function restorePassword(event) {
    event.preventDefault();
    event.target.blur(); // по рекомендации GPT

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      showSnackbar("Введите корректно E-mail", "error");
      return;
    }

    axios
      .post(
        `${HOST}/api/restore_password`,
        { email: email },
        {
          withCredentials: true,
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      )
      .then(async (response) => {
        let resp = response.data;
        showSnackbar(resp.message);
        closeModal();
      })
      .catch((error) => {
        showSnackbar(error.response.data, "error");
      });
  }
  return (
    <React.Fragment>
      <span className="text active" onClick={openModal}>
        Забыли пароль?
      </span>

      {showModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Авторизоваться с помощью E-mail</h1>
                </div>
                <div className="modal-content">
                  <input
                    type="email"
                    ref={inputRef}
                    className="input w-input text-white"
                    maxLength={80}
                    placeholder="Введите ваш E-mail"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Закрыть
                  </button>
                  <button
                    className=" button primary"
                    onClick={(event) => restorePassword(event)}
                  >
                    Отправить E-mail
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
