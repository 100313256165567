import React, { useState } from "react";

export default function TeamMate(props) {
  const { user, openInviteModal } = props;
  function handleInviteButton() {
    openInviteModal(user.email);
  }

  return (
    <React.Fragment>
      <div className="subscriber" key={user.id}>
        <div className="row">
          <div className="col min">
            <div
              className="message-img"
              style={{ backgroundImage: `url(${user.userPic})` }}
            />
          </div>
          <div className="col">
            <div className="row">
              <span className="text bold">{user.userName}</span>
            </div>
            <div className="row">
              <span className="text small">
                {user.mobileNumber}&#8194;{user.email}
              </span>
            </div>
          </div>
          <div className="col min">
            <button
              className="button primary outline small"
              onClick={handleInviteButton}
            >
              Пригласить
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
