import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import CloseIcon from "@material-ui/icons/Close";

export default function TopMenu(props) {
  const { projectId } = props;
  return (
    <div className="top-menu">
      {/* <Link to="/web_app" className="top-menu-nav min mobile-only"> */}
      <Link
        to={`/web_app/project/${projectId}`}
        className="top-menu-nav min mobile-only"
      >
        <CloseIcon />
      </Link>
      {/* </Link> */}
    </div>
  );
}
