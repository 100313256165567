import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputField from "../Components/InputField";

// ICONS
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

//UI
import Slide from "@material-ui/core/Slide";

export default function AddProject(props) {
  const { theme } = props;
  const [showModal, setShowModal] = useState(false);
  const [projectName, setProjectName] = useState("");

  function openModal() {
    setShowModal(true);
  }
  function closeModal() {
    setShowModal(false);
  }
  function addNewProject(event) {
    if (projectName == "") return;
    props.addNewProject(projectName);
    closeModal();
  }

  return (
    <React.Fragment>
      <div className="project-card-wrap new" onClick={openModal}>
        <h2>Создать новый проект</h2>
        <span
          className="icon"
          style={{ position: "absolute", right: "20px", bottom: "20px" }}
        >
          <AddCircleOutlineIcon />
        </span>
      </div>

      {showModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Назовите ваш новый проект</h1>
                </div>

                <div className="modal-content">
                  <div className="row">
                    <div className="col">
                      <InputField
                        label="Название проекта"
                        autoFocus={true}
                        onChangeEvent={setProjectName}
                        iconObj={<AddCircleOutlineIcon />}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <span className=" button" onClick={closeModal}>
                    Отмена
                  </span>
                  <button
                    className=" button primary"
                    type="button"
                    onClick={(event) => addNewProject(event)}
                  >
                    Создать
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
