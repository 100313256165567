import React, { useEffect, useContext, useState } from "react";
import rest from "../../../http/axios";
import ProjectViewContext from "../../../Context/ProjectViewContext";

export default function Diagram(props) {
  const { theme } = props;
  const { projectId, taskId } = useContext(ProjectViewContext);
  const [chartData, setChartData] = useState([]);

  const defaultPopover = {
    show: false,
    position: {},
    data: { name: "", absTimeInDays: 0 },
  };
  const [popover, setPopover] = useState(defaultPopover);

  function updatePopover(event, data) {
    const x =
      event.clientX > document.documentElement.clientWidth - 200
        ? event.clientX - 200
        : event.clientX + 10;

    const y = event.clientY + 10;
    setPopover({ show: true, position: { x, y }, data: data });
  }
  function hidePopover() {
    setPopover(defaultPopover);
  }

  const barColors = [
    "bar-color-0",
    "bar-color-1",
    "bar-color-2",
    "bar-color-3",
    "bar-color-4",
    "bar-color-5",
    "bar-color-6",
    "bar-color-7",
    "bar-color-8",
    "bar-color-9",
    "bar-color-10",
    "bar-color-11",
    "bar-color-12",
    "bar-color-13",
    "bar-color-14",
    "bar-color-15",
  ];

  function prepareDataForUsage(data) {
    const totalTime = data.reduce((acc, item) => {
      return acc + item.accTimeInColumn;
    }, 0);

    const barsData = data.reduce((acc, item) => {
      const percentage = (item.accTimeInColumn / totalTime) * 100; // calculate percentage
      const formattedPercentage = percentage.toFixed(0); // format percentage to 2 decimal places
      const percentageValue = formattedPercentage + "%"; // add percent sign to the end
      const barColor = barColors[acc.length]; // get color for bar
      if (formattedPercentage == 0) return acc;
      acc.push({
        name: item.columnName,
        percentageValue,
        cntTimesInColumn: item.cntTimesInColumn,
        absTimeInDays: (item.accTimeInColumn / 60 / 60 / 24).toFixed(0),
        barColor: barColor,
      });

      return acc;
    }, []);
    setChartData(barsData);
  }

  useEffect(() => {
    rest
      .get(`/load_task_chart_data/${projectId}/${taskId}`)
      .then((response) => {
        prepareDataForUsage(response.data);
      });
  }, []);

  if (chartData.length == 0) {
    return <div />;
  } else {
    return (
      <div className={`content-wrapper ${theme}`}>
        <div className="row">
          <h2 className="h2">Время выполнения</h2>
        </div>
        <div className="row">
          <div className="chart">
            {chartData.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`bar ${item.barColor}`}
                  style={{ width: item.percentageValue }}
                  onMouseEnter={(event) => updatePopover(event, item)}
                  onMouseLeave={() => hidePopover()}
                >
                  <span className="bar-value">{item.percentageValue}</span>
                </div>
              );
            })}
          </div>
        </div>

        {chartData.map((item, key) => {
          return (
            <div className="row" key={key}>
              <div className="col">
                <span className="text small">{item.name}</span>
              </div>
              <div className="col min">
                <b className="text small">{item.absTimeInDays} д.</b>
              </div>
            </div>
          );
        })}
        {popover.show ? (
          <div
            className="bar-popover"
            style={{
              transform: `translate(${popover.position.x}px, ${popover.position.y}px)`,
            }}
          >
            <p className="text small">
              {popover.data.name} - {popover.data.absTimeInDays} д.
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}
