import React, { Component, createRef } from "react";
import ProjectViewContext from "../../../Context/ProjectViewContext";

// ELEMENTS
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from "@material-ui/icons/Search";

// SIGNALS
import { subscribers } from "../TaskView";
//UI
import Slide from "@material-ui/core/Slide";

// ICONS
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SvgIcon from "@material-ui/core/SvgIcon";
import StarIcon from "@material-ui/icons/Star";
// import VisibilityIcon from "@material-ui/icons/Visibility";
import BuildIcon from "@material-ui/icons/Build";
import BlurOnIcon from "@material-ui/icons/BlurOn";

class SubscribersReadOnly extends Component {
  static contextType = ProjectViewContext;

  constructor(props) {
    super(props);
    this.state = {
      showUserDetailModal: false,

      roles: [
        {
          id: "role_1",
          name: "Делаю задачку",
          value: "doSomething",
          selected: "",
          icon:
            "M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z",

          color: "#79da75",
        },
        {
          id: "role_2",
          name: "Очень жду",
          value: "waitSomething",
          selected: "",
          icon:
            "M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z",
          color: "#4dafe0",
        },
        {
          id: "role_3",
          name: "Просто смотрю",
          value: "",
          selected: "",
          icon:
            "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
          color: "#bdbdbd",
        },
      ],
      selectedRole: "",
      selectedUserDetails: {
        userName: "",
        userId: 0,
        email: "",
        userPic: "",
        role: "",
      },
    };
  }

  showUserDetailModal = (userId) => {
    // находим профиль пользователя, по которому кликнули
    let userDetails = subscribers.filter((user) => user.userId == userId)[0];

    this.setState({
      showUserDetailModal: true,
      selectedUserDetails: userDetails,
      selectedRole: userDetails.role,
      // смотрим, есть ли у пользователя какая-то роль, если есть, назначаем ей статус selected
      roles: this.state.roles.map((item) => {
        if (item.value == userDetails.role) {
          item.selected = "selected";
        } else {
          item.selected = "";
        }
        return item;
      }),
    });
  };

  closeUserDetailModal = () => {
    this.setState({ showUserDetailModal: false });
  };

  render() {
    return (
      <div className="modal-navigation">
        {subscribers.length > 0
          ? subscribers.map((user, index) => (
              <div className="col min" key={index}>
                <div
                  className="avatar big"
                  style={{ backgroundImage: `url(${user.userPic})` }}
                  onClick={() => this.showUserDetailModal(user.userId)}
                >
                  {user.role == "waitSomething" ? (
                    <div className="avatar-icon blue">
                      <StarIcon style={{ fontSize: 14 }} />
                    </div>
                  ) : null}

                  {user.role == "doSomething" ? (
                    <div className="avatar-icon green">
                      <BuildIcon style={{ fontSize: 14 }} />
                    </div>
                  ) : null}
                  {user.active == false ? (
                    <div className="avatar-blackout">
                      <BlurOnIcon />
                    </div>
                  ) : null}
                </div>
              </div>
            ))
          : null}

        {this.state.showUserDetailModal ? (
          <React.Fragment>
            <div className="modal-cover active"></div>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Профиль</h1>
                </div>
                <div className="modal-messages">
                  <div className="row">
                    <div className="col">
                      <Avatar
                        style={{ width: 100, height: 100 }}
                        src={this.state.selectedUserDetails.userPic}
                      />
                    </div>
                    <div className="col">
                      <h1 className="h1">
                        {this.state.selectedUserDetails.userName}
                      </h1>
                      {/* <span className="text">kosteyz@mail.ru</span> */}
                    </div>
                  </div>
                  <div className="content-wrapper">
                    <h2 className="h2">Роль в проекте:</h2>

                    {this.state.roles.map((role) => (
                      <label className={`select-item ${role.selected}`}>
                        <input
                          type="radio"
                          id={role.id}
                          name="selectRole"
                          className="hidden-radio"
                          value={role.value}
                        />

                        <SvgIcon style={{ color: `${role.color}` }}>
                          <path d={role.icon} />
                        </SvgIcon>

                        <span className="text">&emsp; {role.name}</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className=" button"
                    onClick={this.closeUserDetailModal}
                  >
                    Закрыть
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default SubscribersReadOnly;
