import React from "react";

// ICONS
import AttachmentIcon from "@material-ui/icons/Attachment";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import NotesIcon from "@material-ui/icons/Notes";
import LinkIcon from "@material-ui/icons/Link";

export default function WidgetNavView(props) {
  const { widgetSettings } = props;
  return (
    <div className="modal-widgets">
      {widgetSettings.showDescription ? null : (
        <div className="widget-item">
          <button
            className=" button black outline small"
            onClick={() =>
              props.changeWidgetSettingsEvent("showDescription", true)
            }
          >
            <NotesIcon style={{ marginRight: "5px" }} />
            Описание
          </button>
        </div>
      )}

      {widgetSettings.showFiles ? null : (
        <div className="widget-item">
          <button
            className=" button done outline small"
            onClick={() => props.changeWidgetSettingsEvent("showFiles", true)}
          >
            <AttachmentIcon style={{ marginRight: "5px" }} />
            Файлы
          </button>
        </div>
      )}
      {widgetSettings.showList ? null : (
        <div className="widget-item">
          <button
            className=" button sky outline small"
            onClick={() => props.changeWidgetSettingsEvent("showList", true)}
          >
            <PlaylistAddCheckIcon style={{ marginRight: "5px" }} />
            Список
          </button>
        </div>
      )}

      {widgetSettings.showBudget ? null : (
        <div className="widget-item">
          <button
            className=" button warning outline small"
            onClick={() => props.changeWidgetSettingsEvent("showBudget", true)}
          >
            <AttachMoneyIcon style={{ marginRight: "5px" }} />
            Бюджет
          </button>
        </div>
      )}

      {widgetSettings.showLinks ? null : (
        <div className="widget-item">
          <button
            className=" button primary outline small"
            onClick={() => props.changeWidgetSettingsEvent("showLinks", true)}
          >
            <LinkIcon style={{ marginRight: "5px" }} />
            Связанные задачи
          </button>
        </div>
      )}
    </div>
  );
}
