import React, { useState, useRef, useEffect } from "react";
import rest from "../../../http/axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";

//UI
import Slide from "@material-ui/core/Slide";

export default function ProjectImage(props) {
  const [loadValue, setLoadValue] = useState(0);
  const [showCover, setShowCover] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [uplodaFileUrl, setUploadFileUrl] = useState(props.projectPic);
  const [showModal, setShowModal] = useState(false);
  const uploadImageRef = useRef();

  const imageMimeType = /image\/(png|jpg|jpeg)/i;

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function preloadPic(event) {
    const file = event.target.files[0];
    if (!file.type.match(imageMimeType)) {
      return;
    }
    setUploadFile(file);
  }

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (uploadFile) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setUploadFileUrl(result);
        }
      };
      fileReader.readAsDataURL(uploadFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [uploadFile]);

  function uploadPic() {
    if (uploadFile) {
      const formData = new FormData();
      formData.append("projectPic", uploadFile, uploadFile.name);
      formData.append("projectId", props.projectId);

      rest
        .post("/update_project_pic", formData, {
          onUploadProgress: (progressEvent) => {
            let value = (progressEvent.loaded / progressEvent.total) * 100;
            setLoadValue(value);
          },
        })
        .then((resp) => {
          const rsp = resp.data;
          props.adoptProjectPicEvent(rsp.imgPath, rsp.message);
          closeModal();
        });
    }
  }
  return (
    <div className="content-wrapper">
      <h1 className="h1">Картинка проекта:</h1>
      <div
        className="master-image"
        style={{ backgroundImage: `url(${props.projectPic})` }}
        onMouseEnter={() => setShowCover(true)}
        onMouseLeave={() => setShowCover(false)}
        onClick={() => {
          openModal();
        }}
      >
        {showCover ? (
          <div
            className="master-image-cover"
            onClick={() => {
              openModal();
            }}
          >
            <ImageSearchIcon style={{ fontSize: "2em" }} />
          </div>
        ) : null}
      </div>

      {showModal ? (
        <React.Fragment>
          <div className="modal-cover" onClick={closeModal} />
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Выбор картинки</h1>
                </div>
                <div className="modal-content">
                  <div
                    className="content-wrapper"
                    style={{ maxWidth: "400px" }}
                  >
                    <div
                      className="master-image"
                      style={{ backgroundImage: `url(${uplodaFileUrl})` }}
                    />

                    <div className="button-wrapper center">
                      <button
                        className="button"
                        onClick={() => uploadImageRef.current.click()}
                      >
                        Выбрать картинку
                      </button>

                      <input
                        type="file"
                        accept="image/*"
                        ref={uploadImageRef}
                        onChange={(event) => preloadPic(event)}
                        className="input w-input"
                        style={{ display: "none" }}
                      />
                    </div>
                    <div className="row">
                      <LinearProgress variant="determinate" value={loadValue} />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="button" onClick={closeModal}>
                    Закрыть
                  </button>
                  <button
                    className="button primary"
                    onClick={() => uploadPic()}
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </div>
  );
}
