import React, { useContext, useState } from "react";
import LabelEditor from "./LabelEditor";
import rest from "../../../http/axios";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EditIcon from "@material-ui/icons/Edit";

//UI
import Slide from "@material-ui/core/Slide";

export default function LabelView(props) {
  const { projectId, taskId, adoptTaskLabelInProject } = useContext(
    ProjectViewContext
  );
  const { socket } = useContext(BasicContext);
  const { columnId, activeLabel, updateTaskLabel, theme } = props;

  const [showModal, setShowModal] = useState(false); // отображение модального окна
  const [labels, setLabels] = useState([]); // список загруженных ярлыков в проекте
  const [mode, setMode] = useState("select"); // select | create
  const [editMode, setEditMode] = useState(false); // добавляет иконки редактирования в режиме select

  const [editedLabel, setEditedLabel] = useState({
    id: null,
    name: "",
    color: "dark-grey",
  }); // объект редактируемого ярлыка

  const [selectedLabel, setSelectedLabel] = useState({
    id: null,
    name: "",
    color: "",
  }); // объект выбранного ярлыка

  function openModal() {
    setShowModal(true);
    rest.get(`/get_labels_list/${projectId}`).then((response) => {
      let rsp = response.data;

      if (rsp.labels.length > 0) {
        let labels = rsp.labels.map((item) => {
          if (item.id === activeLabel.id) {
            item.selected = "selected";
            setSelectedLabel({
              id: item.id,
              name: item.name,
              color: item.color,
            });
          } else {
            item.selected = "";
          }
          return item;
        });
        setLabels(labels);
      } else {
        setEditMode(true);
      }
    });
  }

  function closeModal() {
    setShowModal(false);
    setEditMode(false);
    setMode("select");
  }

  // Управляет выбором активного элемента списка
  function selectLabel(event) {
    let targetId = parseInt(event.target.id);
    let targetValue = event.target.value;
    let targetColor = ""; // создаем пустым и назначаем чуть позднее по id ярлыка

    // Для случая, когда выбран "Без ярлыка"
    if (targetId === 0) {
      targetValue = "";
      targetColor = "";
    }

    // Устанавливаем цвет выбранного ярлыка по его оригинальному значению, полученнму с сервера
    labels.map((item) => {
      if (item.id === targetId) targetColor = item.color;
      return;
    });

    setLabels(
      labels.map((item) => {
        if (item.id === targetId) {
          item.selected = "selected";
        } else {
          item.selected = "";
        }
        return item;
      })
    );

    setSelectedLabel({
      id: targetId,
      name: targetValue,
      color: targetColor,
    });
  }

  function saveSelectedLabel(event) {
    event.preventDefault();

    let labelData = {
      projectId: projectId,
      columnId: columnId,
      taskId: taskId,

      label: {
        id: selectedLabel.id,
        name: selectedLabel.name,
        color: selectedLabel.color,
      },
    };

    socket.emit("update_task_label", labelData);

    updateTaskLabel(labelData);
    adoptTaskLabelInProject(labelData);
    closeModal();
  }

  // Используется вложенным элементом LabelEditor
  // Обновляет список ярлыков в проекте
  function updateLabelsList(labelsList, details) {
    console.log(labelsList);
    // Обновляем список ярлыков в проекте
    setLabels(labelsList);
    // Если ярлык был изменен, обновляем его на TaskView
    if (details.reason === "edit") {
      if (activeLabel.id == details.labelId) {
        let updatedLabel = labelsList.find(
          (item) => item.id === details.labelId
        );
        let labelData = {
          projectId: projectId,
          columnId: columnId,
          taskId: taskId,
          label: {
            id: updatedLabel.id,
            name: updatedLabel.name,
            color: updatedLabel.color,
          },
        };
        updateTaskLabel(labelData);
      }
    } else if (details.reason === "delete") {
      if (activeLabel.id == details.labelId) {
        let labelData = {
          projectId: projectId,
          columnId: columnId,
          taskId: taskId,
          label: {
            id: 0,
            name: "",
            color: "",
          },
        };
        updateTaskLabel(labelData);
      }
    }
  }

  // открывает окно создания нового ярлыка
  function startEditLabelMode(
    labelId = 0,
    labelName = "",
    labelColor = "dark-grey"
  ) {
    setEditMode(false);
    setMode("create");
    setEditedLabel({
      id: labelId,
      name: labelName,
      color: labelColor,
    });
  }

  // закрывает окно создания нового ярлыка
  function finishEditLabelMode() {
    setMode("select");
    setEditedLabel({ id: null, name: "", color: "dark-grey" });
  }

  return (
    <div className="modal-topline-wrapper">
      <div
        className={`label active ${theme} ${activeLabel.color}`}
        onClick={() => openModal()}
      >
        <LocalOfferIcon style={{ fontSize: "1.2em" }} />
        <span style={{ marginLeft: "5px" }}>{activeLabel.name}</span>
      </div>

      {showModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="row modal-header">
                  <div className="col">
                    <h1 className="h1">Выбор ярлыка:</h1>
                  </div>
                  {editMode ? null : (
                    <div className="col min">
                      <button
                        className=" button small"
                        onClick={() => setEditMode(!editMode)}
                      >
                        Редактировать
                      </button>
                    </div>
                  )}
                </div>

                {mode == "select" ? (
                  <div
                    className="modal-content"
                    onChange={(event) => {
                      selectLabel(event);
                    }}
                  >
                    {labels.map((item) => (
                      <label
                        className={`select-item ${item.selected}`}
                        key={item.id}
                      >
                        <div className="col">
                          <input
                            type="radio"
                            id={item.id}
                            name="selectLabel"
                            className="hidden-radio"
                            value={item.name}
                          />
                          <span className="text">{item.name}</span>
                        </div>
                        <div
                          className="col min"
                          style={{ paddingRight: "20px" }}
                        >
                          <div
                            className={`color-picker small ${item.color}`}
                          ></div>
                        </div>
                        {editMode ? (
                          <React.Fragment>
                            <div className="col min">
                              <EditIcon
                                onClick={() =>
                                  startEditLabelMode(
                                    item.id,
                                    item.name,
                                    item.color
                                  )
                                }
                                className="active-icon"
                              />
                            </div>
                          </React.Fragment>
                        ) : null}
                      </label>
                    ))}
                    {editMode ? (
                      <label
                        className="select-item "
                        key={999999999}
                        onClick={() => startEditLabelMode()}
                      >
                        <span className={`h3 black`}>
                          + Добавить новый ярлык
                        </span>
                      </label>
                    ) : null}
                  </div>
                ) : null}

                {/* ДОБАВИТЬ НОВЫЙ ЯРЫК */}
                {mode == "create" ? (
                  <LabelEditor
                    projectId={projectId}
                    editedLabel={editedLabel}
                    labelsList={labels}
                    updateLabelsList={updateLabelsList}
                    finishEditLabelMode={finishEditLabelMode}
                  />
                ) : // </Slide>
                null}

                <div className="modal-footer">
                  {mode == "select" ? (
                    <React.Fragment>
                      <button className=" button" onClick={closeModal}>
                        Закрыть
                      </button>

                      <button
                        className=" button primary"
                        onClick={(event) => saveSelectedLabel(event)}
                      >
                        Выбрать
                      </button>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </div>
  );
}
