import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { HOST } from "../AppConfig";
import AppContext from "../Context/AppContext";

export default function Logout(props) {
  const { logoutUserEvent } = useContext(AppContext);
  const navigate = useNavigate();

  function logout() {
    logoutUserEvent();
    localStorage.clear();
    axios.get(`${HOST}/api/logout`).then((response) => {
      console.log(response);
    });
    // props.history.push("/login");
    navigate("/login");
  }
  useEffect(() => {
    logout();
  }, []);

  return <div>...Выход из Branzy</div>;
}
