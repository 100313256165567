import React, { useState } from "react";

export default function Chart(props) {
  const { row } = props;
  const defaultPopover = {
    show: false,
    position: {},
    data: { name: "", absTimeInHours: 0, color: "" },
  };
  const [popover, setPopover] = useState(defaultPopover);

  function updatePopover(event, bar) {
    const x = event.clientX + 10;
    const y = event.clientY + 10;
    setPopover({ show: true, position: { x, y }, data: bar });
  }
  function hidePopover() {
    setPopover(defaultPopover);
  }

  return (
    <div className="chart">
      {row.barsData.map((bar, index) => (
        <div
          className={`bar ${row.barsData[index].color}`}
          style={{ width: bar.percentageValue }}
          onMouseEnter={(event) => updatePopover(event, bar)}
          onMouseLeave={() => hidePopover()}
          key={index}
        >
          <span className="bar-value">{bar.percentageValue}</span>
        </div>
      ))}

      <React.Fragment>
        {popover.show ? (
          <div
            className="bar-popover"
            style={{
              transform: `translate(${popover.position.x}px, ${popover.position.y}px)`,
            }}
          >
            <p>{popover.data.name}</p>
            <p className="text small">
              <span>Общее время: </span>
              {popover.data.absTimeInHours > 24 ? (
                <span className="bold">
                  {(popover.data.absTimeInHours / 24).toFixed(1)} д.
                </span>
              ) : (
                <span className="bold">
                  Общее время: {(popover.data.absTimeInHours / 1).toFixed(1)} ч.
                </span>
              )}
            </p>
            <p className="text small">
              <span>Была в колонке: </span>
              <span className="bold">{popover.data.cntTimesInColumn} раз </span>
            </p>
          </div>
        ) : null}

        {/* <div className="bar-popover-cover" onClick={hidePopover}></div> */}
      </React.Fragment>
    </div>
  );
}
