import React from "react";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import {
  BsCalendarEvent,
  BsFillFastForwardFill,
  BsBell,
  BsFillRewindFill,
} from "react-icons/bs";

const ntfcImages = {
  emoji: <MdOutlineEmojiEmotions />,
  change_date: <BsCalendarEvent />,
  move_forward: <BsFillFastForwardFill />,
  move_backward: <BsFillRewindFill />,
  "": <BsBell />,
};

function NotificationComponent({ notificationType, count }) {
  return (
    <li className="footer-info-item active success">
      {ntfcImages[notificationType]}&#160; {count}
    </li>
  );
}

export default NotificationComponent;
