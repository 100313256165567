import React, { useState, useRef } from "react";

export default function AddColumnForm(props) {
  const [showForm, setShowForm] = useState(false);
  const { theme } = props;

  const textareaRef = useRef();

  function showAddColumnForm() {
    setShowForm(true);
    setTimeout(() => {
      textareaRef.current.focus();
    }, 200);
  }

  function closeAddColumnForm(event) {
    event.preventDefault();
    setShowForm(false);
  }

  function addNewColumn(event) {
    event.preventDefault();
    props.addColumn(event); // go to ProjectView.js
    closeAddColumnForm(event);
  }

  return (
    <div className={`column ${theme}`}>
      {showForm ? (
        <div className={`column-footer-form w-form active`}>
          <form className="new-project" onSubmit={addNewColumn}>
            <div className="wr">
              <textarea
                ref={textareaRef}
                placeholder="Название колонки"
                className="textarea w-input"
              ></textarea>
            </div>
            <div className="edit-message-settings">
              <button className="button " onClick={closeAddColumnForm}>
                Отмена
              </button>
              <button className="button primary ">Добавить</button>
            </div>
          </form>
        </div>
      ) : (
        <div className="column-header">
          <span
            className={`column-base-nav active ${theme}`}
            onClick={showAddColumnForm}
          >
            + Добавить новую колонку
          </span>
        </div>
      )}
    </div>
  );
}
