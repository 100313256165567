import React, { useState, useEffect, useContext } from "react";
import Slide from "@material-ui/core/Slide";
import rest from "../../../http/axios";

export default function VersionSelector(props) {
  const {
    taskId,
    version,
    // createNewDescriptionVersion,
    loadDescriptionVersion,
    compareDescriptionVersions,
    theme,
  } = props;

  const [modalShow, setModalShow] = useState(false);
  const [items, setItems] = useState([]);

  const showModal = () => {
    setModalShow(true);
    rest.get(`/load_task_description_versions/${taskId}`).then((response) => {
      let versionItems = response.data;
      versionItems = versionItems.map((item) => {
        if (item.version === version) {
          item.active = "selected";
        } else {
          item.active = "";
        }
        return item;
      });
      setItems(versionItems);
    });
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const changeRadio = (event) => {
    let targetId = parseInt(event.target.id);

    setItems(
      items.map((item) => {
        if (item.id === targetId) {
          item.active = "selected";
        } else {
          item.active = "";
        }
        return item;
      })
    );
  };

  const chooseVersion = () => {
    let selectedVersion = items.find((item) => item.active === "selected");

    loadDescriptionVersion(selectedVersion.id);
    closeModal();
  };

  // const createNewVersionInList = () => {
  //   let newVersion = version + 1;
  //   let currentItems = items.map((item) => {
  //     item.active = "";
  //     return item;
  //   });

  //   setItems([
  //     {
  //       id: newVersion,
  //       name: "Версия " + newVersion,
  //       active: "selected",
  //     },
  //     ...currentItems,
  //   ]);
  //   createNewDescriptionVersion(newVersion);
  //   closeModal();
  // };

  return (
    <React.Fragment>
      <div className="col min vertical-center" onClick={() => showModal()}>
        <strong className={`text dark-blue active ${theme}`}>
          Версия №{version}
        </strong>
      </div>
      {modalShow ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={modalShow} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Выберите версию:</h1>
                </div>
                <div className="modal-content" onChange={changeRadio}>
                  {items.map((item) => (
                    <label
                      className={`select-item ${item.active}`}
                      key={item.id}
                    >
                      <div className="col min">
                        <input
                          type="radio"
                          id={item.id}
                          name="versionNumber"
                          className="hidden-radio"
                        />
                        <span className="w-form-label text bold">
                          №{item.version}
                        </span>
                      </div>
                      <ddiv className="col min">
                        <div
                          className="avatar"
                          style={{
                            backgroundImage: `url("${item.userPic}")`,
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        ></div>
                      </ddiv>
                      <div className="col">
                        <p className="text">{item.userName}</p>
                        <span className="text small">{item.dateTime}</span>
                      </div>

                      <div className="col min">
                        <button
                          className="button small"
                          onClick={() => {
                            compareDescriptionVersions(item.id);
                            closeModal();
                          }}
                        >
                          Сравнить
                        </button>
                      </div>
                    </label>
                  ))}
                  {/* <div className="button-wrapper right">
                    <button
                      className="button sky outline small"
                      onClick={() => {
                        createNewVersionInList();
                      }}
                    >
                      Добавить новую версию
                    </button>
                  </div> */}
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Отмена
                  </button>
                  <button
                    type="submit"
                    className=" button primary"
                    onClick={chooseVersion}
                  >
                    Выбрать
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
