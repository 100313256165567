import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FiCopy } from "react-icons/fi";
import rest from "../../http/axios";
import BasicContext from "../../Context/BasicContext";

//UI
import Slide from "@material-ui/core/Slide";

// ICONS
import { IoIosShareAlt } from "react-icons/io";

import {
  EmailShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailIcon,
  TelegramIcon as TgIcon,
  WhatsappIcon,
} from "react-share";

// Styles
const useStyles = makeStyles({
  shareButton: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    marginTop: "5px",
    marginRight: "5px",
  },
});

export default function ShareTask(props) {
  const { taskId, taskName, changeWidgetSettingsEvent } = props;
  const { socket } = useContext(BasicContext);

  const [showModal, setShowModal] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const styles = useStyles();

  function openModal() {
    setShowModal(true);
  }
  function closeModal() {
    setShowModal(false);
  }

  function handleSendButton() {
    let list = [];
    projectList.map((item) => {
      if (item.active === "selected") {
        list.push(item.id);
      }
    });
    if (list.length === 0) {
      props.showSnackBar("Выберите проект");
      return;
    }
    let payload = {
      taskId: taskId,
      list: list,
    };

    changeWidgetSettingsEvent("showLinks", true);
    socket.emit("delegate_task", payload, (data) => {
      props.showSnackBar("Задача отправлена на другую доску");
    });

    closeModal();
  }
  useEffect(() => {
    if (showModal) {
      rest.get("/load_project_list_minified").then((response) => {
        let list = response.data.map((item) => {
          item.active = "";
          return item;
        });
        setProjectList(list);
      });
    }
  }, [showModal]);

  function copyToClickboard() {
    let path = window.location.href;
    navigator.clipboard.writeText(path);
    closeModal();
    props.showSnackBar("Скопировано");
  }

  function changeRadio(event) {
    let targetId = parseInt(event.target.id);
    setProjectList(
      projectList.map((item) => {
        if (item.id === targetId) {
          if (item.active === "") {
            item.active = "selected";
          } else {
            item.active = "";
          }
        }
        return item;
      })
    );
  }

  return (
    <React.Fragment>
      <button className={`button`} onClick={openModal}>
        <IoIosShareAlt
          className="icon small"
          style={{
            marginRight: "5px",
          }}
        />
        <span className="desktop-only">Отправить</span>
      </button>
      {showModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Отправить:</h1>
                </div>
                <div className="row">
                  <div className="col center">
                    <div
                      className="color-picker yellow"
                      style={{ marginTop: "6px", marginBottom: "5px" }}
                      onClick={() => {
                        copyToClickboard();
                      }}
                    >
                      <FiCopy style={{ color: "white" }} />
                    </div>
                    Копировать
                  </div>
                  <div className="col center">
                    <EmailShareButton
                      url={window.location.href}
                      subject="Ссылка на проект Branzy"
                      body={taskName}
                    >
                      <EmailIcon className={styles.shareButton} />
                    </EmailShareButton>
                    Email
                  </div>
                  <div className="col center">
                    <TelegramShareButton
                      url={window.location.href}
                      title={taskName}
                    >
                      <TgIcon className={styles.shareButton} />
                    </TelegramShareButton>
                    Telegram
                  </div>
                  <div className="col center">
                    <WhatsappShareButton
                      url={window.location.href}
                      title={taskName}
                    >
                      <WhatsappIcon className={styles.shareButton} />
                    </WhatsappShareButton>
                    WhatsApp
                  </div>
                </div>

                <h3 style={{ padding: "10px 20px" }}>Отправить в проект:</h3>

                <div className="modal-content" onChange={changeRadio}>
                  {projectList.map((item) => (
                    <label
                      className={`select-item w-radio ${item.active}`}
                      key={item.id}
                    >
                      <div
                        className="project-img"
                        style={{ backgroundImage: `url(${item.pic})` }}
                      />

                      <input
                        type="radio"
                        id={item.id}
                        name="moveToColumn"
                        className="hidden-radio"
                        value={item.name}
                      />
                      <span
                        // htmlFor="Radio1"
                        className="w-form-label"
                      >
                        {item.name}
                      </span>
                    </label>
                  ))}
                </div>

                <div className="modal-footer">
                  <button className="button" onClick={closeModal}>
                    Закрыть
                  </button>
                  <button
                    className="button primary"
                    onClick={() => handleSendButton()}
                  >
                    Отправить
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
