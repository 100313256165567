import React, { useContext } from "react";
import ProjectViewContext from "../../../Context/ProjectViewContext";
// UI
import CloseIcon from "@material-ui/icons/Close";

export default function MinifiedTask(props) {
  const { taskObj, theme, removeMinifiedTaskEvent } = props;
  const { showTaskModalEvent } = useContext(ProjectViewContext);

  return (
    <div className={`minified-task ${theme}`}>
      <div className="row">
        <div
          className="minified-task-body"
          onClick={() => showTaskModalEvent(taskObj.taskId)}
        >
          <span className={`label ${taskObj.labelColor} ${theme}`}>
            {taskObj.label}
          </span>
          <span className={`text small`}>{taskObj.taskName}</span>
        </div>
        <div
          className="col min"
          onClick={() => removeMinifiedTaskEvent(taskObj.taskId)}
        >
          <CloseIcon className={`active-icon small`} />
        </div>
      </div>
    </div>
  );
}
